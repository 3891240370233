import {Box, Button, Divider, Drawer, Grid, IconButton, Snackbar, Tab, Tabs, Tooltip, Typography} from '@mui/material';
import {
    DataGrid,
    GridCallbackDetails,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueFormatterParams,
    MuiEvent
} from '@mui/x-data-grid';
import React, {useState} from "react";
import Project from "@/components/project/Project";
import Survey from "@/components/survey/Survey";
import ProjectType from "@/types/ProjectType";
import SurveyType from "@/types/SurveyType";
import {getCSSamples} from '../../../services/apiCalls';
import {
    ICSSample
} from '@/types/CommunityScienceSamplesApiResponseType';
import CopyToClipboard from 'react-copy-to-clipboard';
import {TabPanel} from './TabPanel';
import {ViewQuestionResponses} from './ViewQuestionResponses';
import {ViewDocuments} from './ViewDocuments';
import BlockIcon from '@mui/icons-material/Block';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VerifiedIcon from '@mui/icons-material/Verified';

function ViewCSSamples() {
    const [project, setProject] = useState<ProjectType | null>(null);
    const [survey, setSurvey] = useState<SurveyType | null>(null);
    const [errors, setErrors] = useState<Array<string>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [csSamples, setCSSamples] = useState<Array<ICSSample>>([])
    const [toggleDrawer, setToggleDrawer] = useState<boolean>(false);
    const [selectedSample, setSelectedSample] = useState<ICSSample>();
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
    const [taxonTabValue, setTaxonTabValue] = useState<string | null>(null);
    const [responsesTabValue, setResponsesTabValue] = useState<"general" | "taxon_specific">("general");

    const columns: GridColDef[] = [
        {
            field: 'verification_completion_status',
            headerName: 'Verification Completion Status',
            flex: 2,
            headerClassName: 'cs_sample_header',
            align: "center", headerAlign: "center",
            renderCell: (params) => {
                const verificationCompletionStatus: string = params.row['verification_completion_status'];
                if(verificationCompletionStatus == "COMPLETE"){
                    return <Tooltip title="COMPLETE">
                                <VerifiedIcon color="primary"></VerifiedIcon>
                            </Tooltip>
                }
                if(verificationCompletionStatus == "IN_PROGRESS") {
                    return <Tooltip title="IN_PROGRESS">
                                <HourglassTopIcon></HourglassTopIcon>
                            </Tooltip>
                }
                if(verificationCompletionStatus == "NOT_IN_PROGRESS"){
                    return <Tooltip title="NOT_IN_PROGRESS">
                                <HourglassEmptyIcon></HourglassEmptyIcon>
                            </Tooltip>
                }
                if(verificationCompletionStatus == "NOT_REQUIRED") {
                    return <Tooltip title="NOT_REQUIRED">
                                <BlockIcon></BlockIcon>
                            </Tooltip>
                }
            }
        },
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.5,
            headerClassName: 'cs_sample_header',
            align: "center", headerAlign: "center",
            renderCell: (params: GridRenderCellParams) => {
                const { id } = params.row;
                return <Box onClick={(e: any) => e.stopPropagation()}>
                    <CopyToClipboard text={id} onCopy={() => setOpenSnackBar(true)}>
                        <Tooltip title={id}>
                            <IconButton color="primary" component="label" size="small">
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                </Box>
            }
        },

        {
            field: 'Sample Details',
            headerName: 'Sample Details',
            flex: 4,
            headerClassName: 'cs_sample_header',
            valueGetter: (params) => {
                return params.row['last_name'] + ", " + params.row['first_name'];
            },
            cellClassName: 'cs_sample_sample_details'
        },
        { field: 'sample_observers_number', headerName: 'No. of Participants', flex: 2, headerClassName: 'cs_sample_header', },
        { field: 'submission_datetime', headerName: 'Submission Date', flex: 2, headerClassName: 'cs_sample_header',
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.value == null) {
                    return '';
                }
                return params.value.substring(0,10);
            }},
    ];

    const handleTaxonTab = (event: React.SyntheticEvent, newValue: string) => {
        setTaxonTabValue(newValue);
    };

    const handleResponsesTab = (event: React.SyntheticEvent, newValue: "general" | "taxon_specific") => {
        setResponsesTabValue(newValue);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrors([])
        setLoading(true)
        setCSSamples([])

        try {
            const csSamples = await getCSSamples(
                survey?.id || "")

            setCSSamples(csSamples)
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }

        setLoading(false)
    }

    const handleRowClick = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        window.open(`#/community-science/samples/${params.id}`, '_blank');
    }

    return (
        <>
        <Grid container direction="column" style={{paddingLeft: 20}}>
          <Grid item id="heading" mb={2}>
                <Typography variant="h3" gutterBottom>Community Science Samples</Typography>
          </Grid>
          <Grid item id="control">
            <form onSubmit={handleSubmit}>
                <Grid container direction="row" spacing={2} alignItems="flex-end">
                    <Grid item xs={4}>
                        <Project value={project?.projectName || null} handleChange={setProject} />
                    </Grid>
                    <Grid item xs={4}>
                        <Survey value={survey?.surveyName || null} handleChange={setSurvey} project={project}/>
                    </Grid>
                    <Grid item xs={2} alignSelf="center">
                        <Button size="large" variant="contained" color="warning" type={"submit"}>Go</Button>
                    </Grid>
                </Grid>
            </form>
          </Grid>
          <Grid item><Divider sx={{mb: 3, mt: 3}}/></Grid>
          <Grid item id="data" height={600}>
            <DataGrid
                  rows={csSamples}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  pageSize={15}
                  loading={loading}
                  onRowClick={handleRowClick}
              />
              <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={openSnackBar}
                    onClose={() => setOpenSnackBar(false)}
                    message="ID is copied to clipboard"
                    autoHideDuration={5000}
                />
          </Grid>
          <React.Fragment key='project-drawer'>
                <Drawer
                    anchor="right"
                    open={toggleDrawer}
                    onClose={() => setToggleDrawer(false)}
                >
                    <Box sx={{ width: '50vw', p: '15px', bgcolor: '#EEE', height: '100%' }}>
                        <Typography variant='h5' gutterBottom>{selectedSample?.last_name}, {selectedSample?.first_name}</Typography>
                        <Box sx={{ mb: 2, mt: 2 }}>
                            <Typography variant="overline" display="block">Sample Id</Typography>
                            {selectedSample?.id && <CopyToClipboard text={selectedSample?.id} onCopy={(e: any) => {
                                setOpenSnackBar(true);
                            }}>
                                <span style={{ userSelect: 'all', cursor: 'pointer' }}>{selectedSample?.id}</span>
                            </CopyToClipboard>}
                        </Box>
                        <Typography variant="overline" display="block">Sample Notes</Typography>
                        <Typography variant="body2" gutterBottom>
                            {selectedSample?.sample_notes}
                        </Typography>

                        <Box sx={{ mb: 2, mt: 2 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={responsesTabValue} onChange={handleResponsesTab} aria-label="Responses">
                                    <Tab label="General responses" value="general" />
                                    <Tab label="Taxon responses" value="taxon_specific" />
                                </Tabs>
                            </Box>
                            <TabPanel value={responsesTabValue} index="general">
                                <Typography variant="overline" display="block">Sample Weather</Typography>
                                <Typography variant="body2" gutterBottom>
                                    {selectedSample?.sample_weather}
                                </Typography>
                                <Box sx={{ mb: 2, mt: 2 }}>
                                    <ViewDocuments value={selectedSample?.document_list}/>
                                </Box>
                                <Box sx={{ mb: 2, mt: 2 }}>
                                    <ViewQuestionResponses value={selectedSample?.question_responses}/>
                                </Box>
                            </TabPanel>
                            <TabPanel value={responsesTabValue} index="taxon_specific">
                                <Box sx={{ mb: 2, mt: 2 }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={taxonTabValue} onChange={handleTaxonTab} aria-label="Taxon responses">
                                            {selectedSample?.taxon_responses?.map((taxon) => (
                                                <Tab label={taxon.taxon_name} value={taxon.taxon_name} />
                                            ))}
                                        </Tabs>
                                    </Box>
                                    {selectedSample?.taxon_responses?.map((taxon) => (
                                        <TabPanel value={taxonTabValue} index={taxon.taxon_name}>
                                            <Box sx={{ mb: 2, mt: 2 }}>
                                                <ViewDocuments value={taxon.document_list}/>
                                            </Box>
                                            <Box sx={{ mb: 2, mt: 2 }}>
                                                <ViewQuestionResponses value={taxon.question_responses}/>
                                            </Box>
                                        </TabPanel>
                                    ))}
                                </Box>
                            </TabPanel>
                        </Box>
                    </Box>

                </Drawer>

            </React.Fragment>
        </Grid>
        </>
    )
}

export default ViewCSSamples;