import DashboardIcon from '@mui/icons-material/Dashboard';
import ScienceIcon from '@mui/icons-material/Science';
import PollIcon from '@mui/icons-material/Poll';
import ClassIcon from '@mui/icons-material/Class';
import CalculateIcon from '@mui/icons-material/Calculate';
import BiotechIcon from '@mui/icons-material/Biotech';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import LoopIcon from '@mui/icons-material/Loop';
import { SupervisedUserCircle } from '@mui/icons-material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: <DashboardIcon />,
  },
  {
    title: 'projects',
    path: '/projects',
    icon: <ScienceIcon />,
  },
  {
    title: 'surveys',
    path: '/surveys',
    icon: <PollIcon />,
  },
  {
    title: 'Acoustics',
    path: "/acoustics",
    icon: <VolumeUpIcon />,
  },
  {
    title: 'Environmental Monitoring',
    path: "/environmental-monitoring",
    icon: <EmojiNatureIcon />,
  },
  {
    title: 'sampling protocols',
    path: '/protocols/sampling',
    icon: <ClassIcon />,
  },
  {
    title: 'processing protocols',
    path: '/protocols/processing',
    icon: <CalculateIcon />,
  },
  {
    title: 'observations',
    path: '/observations',
    icon: <BiotechIcon />,
  },
  {
    title: 'community science',
    path: '/community-science/samples',
    icon: <SupervisedUserCircle />,
  },
  {
    title: 'Sandbox',
    path: '/analysis-tool/sandbox',
    icon: <TipsAndUpdatesIcon />,
  },
  {
    title: 'Processing Outputs',
    path: '/processing-outputs',
    icon: <LoopIcon />,
  },
];

export default navConfig;
