import { DivStyle } from "./VerificationStyles";
import TextField from '@mui/material/TextField';
import { Button, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from "react";
import { CommunityScienceProcessingOutputResponseType, CommunityScienceTaxonHierarchyType } from "@/types/CommunityScienceSamplesApiResponseType";
import TaxonField from "./TaxonField";
import { getAWSS3Link, getAvailableMediaForProcessingOutput, postCommunityScienceVerificationRecord } from "../../services/apiCalls";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Auth } from "aws-amplify";

enum Level1Status {
    Accepted = 'Accepted',
    NotAccepted = 'Not Accepted',
    Unconfirmed = 'Unconfirmed'
}

enum Level2Status {
    //accepted
    Correct = 'Correct',
    ConsideredCorrect = 'Considered Correct',
    //not accepted
    UnableToVerify = 'Unable to Verify',
    Incorrect = 'Incorrect',
    //unconfirmed
    Plausible = 'Plausible',
    NotReviewed = 'Not Reviewed'
}

type Media = {
    url: string | undefined,
    id: string
}

function VerificationRecordForm(
    props: {
        selectedProcessingOutput: CommunityScienceProcessingOutputResponseType | null,
        addProcessingOutput: (arg0: CommunityScienceProcessingOutputResponseType) => void,
        handleClose: () => void
    }
) {
    const selectedProcessingOutput = props.selectedProcessingOutput;
    const activeVerificationRecord = selectedProcessingOutput?.verification_records?.find((verificationRecord) => verificationRecord.active) || null

    const [level1Status, setLevel1Status] = useState<string>(activeVerificationRecord?.level_1_status || Level1Status.Accepted);
    const [level2Status, setLevel2Status] = useState<string>(activeVerificationRecord?.level_2_status || Level2Status.Correct);
    const [observationCount, setObservationCount] = useState<number>(activeVerificationRecord?.observation_count || 0);
    const [taxonHierarchy, setTaxonHierarchy] = useState<CommunityScienceTaxonHierarchyType>(activeVerificationRecord?.taxon_hierarchy || {} as CommunityScienceTaxonHierarchyType);
    const [taxonVersionKey, setTaxonVersionKey] = useState<string>(activeVerificationRecord?.taxon_version_key || props.selectedProcessingOutput?.taxon_version_key || "");
    const [imageContent, setImageContent] = useState<JSX.Element | null>(null);
    const [mediaToRender, setMediaToRender] = useState<Media[]>([]);
    const [selectedMediaIds, setSelectedMediaIds] = useState<string[]>(activeVerificationRecord?.media_ids || []);
    const [level2StatusContent, setLevel2StatusContent] = useState<Level2Status[]>([]);
    const [tvkLookupPerformed, setTvkLookupPerformed] = useState<boolean>(taxonVersionKey !== "");


    const handleCreate = () => {
        if(taxonHierarchy.kingdom === undefined && taxonHierarchy.phylum === undefined && taxonHierarchy.superclass === undefined && taxonHierarchy.class === undefined && taxonHierarchy.order === undefined && taxonHierarchy.family === undefined && taxonHierarchy.genus === undefined && taxonHierarchy.species === undefined){
            alert("Please enter a valid TVK and perform lookup.")
            return
        }
        if(!observationCount || observationCount < 1){
            alert("Invalid observation count. Must be number greater than 0.")
            return
        }
        if(mediaToRender.length > 0 && selectedMediaIds.length === 0){
            alert("Please select at least one image to add to the verification record.")
            return
        }
        Auth.currentAuthenticatedUser().then((user) => {
            postCommunityScienceVerificationRecord(
                {
                    observation_count: observationCount,
                    media_ids: selectedMediaIds,
                    taxon_version_key: taxonVersionKey, 
                    level_1_status: level1Status, 
                    level_2_status: level2Status, 
                    taxon_hierarchy: taxonHierarchy,
                    active: true,
                    verified_by: user.username,
                },
                selectedProcessingOutput?.id!
            ).then((processingOutput: CommunityScienceProcessingOutputResponseType) => {
                props.addProcessingOutput(processingOutput);
                props.handleClose();
            }).catch((error) => {
                alert("Error creating verification record: " + error)
            })
        })
    }

    useEffect(() => {
        if(level1Status === Level1Status.Accepted){
            setLevel1Status(Level1Status.Accepted)
            setLevel2Status(Level2Status.Correct)
            setLevel2StatusContent(
                [Level2Status.ConsideredCorrect, Level2Status.Correct]
            )
        } else if(level1Status === Level1Status.NotAccepted){
            setLevel1Status(Level1Status.NotAccepted)
            setLevel2Status(Level2Status.UnableToVerify)
            setLevel2StatusContent(
                [Level2Status.Incorrect, Level2Status.UnableToVerify]
            )
        } else if(level1Status === Level1Status.Unconfirmed){
            setLevel1Status(Level1Status.Unconfirmed)
            setLevel2Status(Level2Status.Plausible)
            setLevel2StatusContent(
                [Level2Status.NotReviewed, Level2Status.Plausible]
            )
        }
    }, [level1Status]);

    useEffect(() => {
        if(selectedProcessingOutput){
            getAvailableMediaForProcessingOutput(
                selectedProcessingOutput.id
            ).then((availableMediaIds: Array<string>) => {
                setMediaToRender(availableMediaIds.map((id) => ({url: "https://placehold.co/600x400?text=loading+image", id: id})))
                availableMediaIds.forEach((id) => {
                    getAWSS3Link("/community-science/media/" + id).then(({url}) => {
                        setMediaToRender(mtr => mtr.map((media) => {
                            if(media.id === id){
                                media.url = url;
                            }
                            return media;
                        }))
                    })
                });
            })
        }
    }, [selectedProcessingOutput]);

    useEffect(() => {
        setImageContent(
            <div style={{overflowX: 'auto', whiteSpace:'nowrap'}}>
                {mediaToRender.map((item) => (
                    <div key={item.id} style={{display: 'inline-block', margin: "3%", width: "30%", height: "100%", position: "relative"}}>
                        <img
                            src={item.url}
                            alt={item.id}
                            loading="lazy"
                            style={{height: '100%', opacity: selectedMediaIds.includes(item.id) ? "50%" : "100%", width: '100%', objectFit: 'contain', border: '1px solid black'}}
                            onClick={() => setSelectedMediaIds(selectedMediaIds.includes(item.id) ? selectedMediaIds.filter((id) => id !== item.id) : [...selectedMediaIds, item.id])}
                        />
                        {selectedMediaIds.includes(item.id) ? <div style={{pointerEvents: "none", position: "absolute", top: 0, left: 0}}><CheckBoxIcon/></div> : null}
                    </div>
                ))}
            </div>
        );
    }, [mediaToRender, selectedMediaIds]);

    return(
        <div style={{...DivStyle("80%"), maxHeight: "80%", overflowY: 'auto'}}>
            <form style={{ textAlign: 'center', maxHeight: '100%', width: '80%'}}>
                <fieldset style={{ border: 'none'}}>
                    <Grid>
                        <Grid item md={4}>
                            <Grid container spacing={2}>
                                <Grid item id={"level1Status"} xs={12}>
                                    <InputLabel>Level 1 Status</InputLabel>
                                    <Select
                                        fullWidth
                                        id="verification-level-1-status"
                                        value={level1Status}
                                        onChange={(event: SelectChangeEvent) => {setLevel1Status(event.target.value as Level1Status);}}
                                    >
                                        <MenuItem value={Level1Status.Accepted}>{Level1Status.Accepted}</MenuItem>
                                        <MenuItem value={Level1Status.NotAccepted}>{Level1Status.NotAccepted}</MenuItem>
                                        <MenuItem value={Level1Status.Unconfirmed}>{Level1Status.Unconfirmed}</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item id={"level2Status"} xs={12}>
                                    <InputLabel>Level 2 Status</InputLabel>
                                    <Select
                                        id="verification-level-2-status"
                                        value={level2Status}
                                        fullWidth
                                        onChange={(event: SelectChangeEvent) => {setLevel2Status(event.target.value as Level2Status);}}
                                    >
                                        {level2StatusContent.map((status) => (<MenuItem key={status} value={status}>{status}</MenuItem>))}
                                    </Select>
                                </Grid>
                                <Grid item id={"media"} xs={12}>
                                    <InputLabel>Please select the images to be added to the record.</InputLabel>
                                    {imageContent}
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Enter Number of Observations</InputLabel>
                                    <TextField
                                        value={observationCount}
                                        inputProps={{min: 0, style: { textAlign: 'center' }}} 
                                        type="number"
                                        onChange={(e) => setObservationCount(parseInt(e.target.value))}
                                        fullWidth
                                    />
                                </Grid>
                                <TaxonField 
                                    taxonVersionKey={taxonVersionKey}
                                    setTaxonVersionKey={setTaxonVersionKey} 
                                    taxonHierarchy={taxonHierarchy} 
                                    setTaxonHierarchy={setTaxonHierarchy}
                                    tvkLookupPerformed={tvkLookupPerformed}
                                    setTvkLookupPerformed={setTvkLookupPerformed}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </fieldset>
                <div style={{textAlign: 'center'}}><Button disabled={!tvkLookupPerformed} variant='contained' onClick={handleCreate}>Submit Verification Record</Button></div>
            </form>
        </div>
    )
}

export default VerificationRecordForm;