import {
    CommunityScienceProcessingOutputResponseType,
    CommunityScienceVerificationCompletionStatusEnum,
    TaxonResponse
} from "@/types/CommunityScienceSamplesApiResponseType";
import {AccordionDetails, AccordionSummary, Divider, Grid, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {CSTable} from "./ParameterValueTable";
import VerificationRecordTables from "./VerificationRecordTables";
import Accordion from "@mui/material/Accordion";
import {useState} from "react";
import {renderVerificationCompletionStatus} from "../common/Functions";


function IndividualTaxonResponse(
    props: {
        taxon_response: TaxonResponse,
        index: number
        renderFilesAsDownloadLinks: (arg0: TaxonResponse) => JSX.Element
        sample_id: string
        processingOutputList: Array<CommunityScienceProcessingOutputResponseType>
        setProcessingOutputList: (tvk: string, processingOutputList: Array<CommunityScienceProcessingOutputResponseType>) => void
        setProcessingOutput: (processingOutput: CommunityScienceProcessingOutputResponseType) => void
        triggerVerificationModal: () => void
        createVerificationRecord: (taxonResponse: TaxonResponse) => void
        verifiedImages: Array<string>
    }
){
    const [isRecordVerified, setIsRecordVerified] = useState(false);
    const [verificationCompletionStatusEnum, setVerificationCompletionStatusEnum] = useState(CommunityScienceVerificationCompletionStatusEnum.NOT_IN_PROGRESS)

    return(
        <Accordion key={props.index}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{
                    backgroundColor: "#e0e0e0",
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderRadius: "5px",
                    marginBottom: "10px"

                }}
            >
                {renderVerificationCompletionStatus(`View submission information for taxon: ${props.taxon_response.taxon_name}`, verificationCompletionStatusEnum)}
            </AccordionSummary>
            <AccordionDetails>
                <Typography align='center' variant="h5" gutterBottom>Taxon Name: {props.taxon_response.taxon_name}</Typography>
                <Grid item><Divider sx={{ mb: 3, mt: 3 }} /></Grid>
                <Typography align='center' variant="h6" gutterBottom>Observation Count: {props.taxon_response.observation_count}</Typography>
                <Grid item><Divider sx={{ mb: 3, mt: 3 }} /></Grid>
                <Grid item id="question_responses" mb={2}>
                    <CSTable question_responses={props.taxon_response.question_responses} table_header="Question Responses" header_weight="h5"/>
                    <Grid item><Divider sx={{ mb: 1, mt: 3 }} /></Grid>
                    {props.renderFilesAsDownloadLinks(props.taxon_response)}
                    <Grid item><Divider sx={{ mb: 1, mt: 3 }} /></Grid>
                    <VerificationRecordTables sampleId={props.sample_id!} taxonResponse={props.taxon_response} processingOutputList={props.processingOutputList} setProcessingOutputList={props.setProcessingOutputList} setProcessingOutput={props.setProcessingOutput} triggerVerificationModal={props.triggerVerificationModal} createVerificationRecord={props.createVerificationRecord} verifiedImages={props.verifiedImages} setIsRecordVerified={setIsRecordVerified} setVerificationStatusEnum={setVerificationCompletionStatusEnum}/>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default IndividualTaxonResponse;