import {TextField} from "@mui/material";
import React from "react";

export default function SampleColumnRange(props: { columnRange: string, onChange: (e: any) => void }) {
    const error = props.columnRange !== "" && !props.columnRange.match(/\d+-\d+/)

    return <TextField
        required
        error={error}
        helperText={error ? "Malformed column range" : null}
        name="sampleColumnRange"
        value={props.columnRange}
        onChange={(e) => props.onChange(e.target.value)}
        label="Sample columns range (e.g. 1-9)"
        variant="outlined"
        fullWidth
    />;
}