import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { QuestionResponse } from "../../customTypes/CommunityScienceSamplesApiResponseType";
import { Variant } from "@mui/material/styles/createTypography";

function CSTableRow(
    props: {
        parameter?: string | "undefined",
        value?: string | "undefined",
        index?: number | 0
    }) {
    return (
        <TableRow key={props.index + "_row"}>
            <TableCell align="center" colSpan={2} key={props.index + "_parameter"}>
                {props.parameter}
            </TableCell>
            <TableCell align="center" colSpan={2} key={props.index + "_value"}>
                {props.value}
            </TableCell>
        </TableRow>
    )
}


function CSTableHeader(
    props: {
        parameter_header?: string | "Parameter",
        value_header?: string | "Value",
    }) {
    return (
        <TableHead>
            <CSTableRow parameter={props.parameter_header} value={props.value_header} />
        </TableHead>
    )
}

function CSTable(
    props: {
        question_responses: QuestionResponse[],
        table_header?: string,
        header_weight?: Variant
    }) {
    let table_rows: JSX.Element[] = [];
    if(props.question_responses){
        table_rows = props.question_responses.map((
            { question_label, question_response }: QuestionResponse, index: number
        ) => {
            return (<CSTableRow parameter={question_label} value={question_response} key={index}/>);
        });
    }
    if(props.table_header){
        return (
            <div>
                <Typography style={{
                    paddingTop: '1vh',
                    paddingBottom: '1vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '4vh',
                    marginBottom: '0vh'
                }} variant={props.header_weight ?? "h4"} gutterBottom>{props.table_header}</Typography>
                <Table style={{ tableLayout: 'fixed' }} stickyHeader aria-label="sticky table">
                    <CSTableHeader parameter_header="Parameter" value_header="Value"/>
                    <TableBody>
                        {table_rows}
                    </TableBody>
                </Table>
            </div>
        )
    }
    else{
        return (
            <div>
                <Table style={{ tableLayout: 'fixed' }} stickyHeader aria-label="sticky table">
                    <CSTableHeader parameter_header="Parameter" value_header="Value"/>
                    <TableBody>
                        {table_rows}
                    </TableBody>
                </Table>
            </div>
        )
    }

}

export { CSTableRow, CSTableHeader, CSTable };