import {getSurveysByProjectId, postSurvey} from "./apiCalls";
import {CreateSurveyType} from "../customTypes/CreateSurveyType";
import APIResponseType from "../customTypes/APIResponseType";
import SurveyType from "../customTypes/SurveyType";

export async function createSurvey(formData: any): Promise<APIResponseType<void>> {
    const payload: CreateSurveyType = {
        project_id: formData.projectId,
        survey_name: formData.surveyName,
        survey_description: formData.description,
        sampling_protocol_id: formData.samplingProtocolId,
        instruction_documents: formData.instructionDocuments,
        start_date: formData.startDate,
        end_date: formData.endDate,
        administrators: formData.administrators
    }

    try {
        await postSurvey(payload)
        return {isSuccessful: true}
    } catch (err: any) {
        const errorMessages = err.response.data.detail ? [err.response.data.detail] : getMessages(err)
        return {isSuccessful: false, errorMessages: errorMessages}
    }
}

export async function fetchSurveysByProjectId(projectId: string): Promise<Array<SurveyType>> {
    const surveyApiResponse = await getSurveysByProjectId(projectId)

    return (surveyNameAndId(surveyApiResponse));
}

const surveyNameAndId = (apiResponse: any) => {
    return apiResponse.reduce((arr: any, currentValue: any) => {
        arr.push({
            surveyName: currentValue.survey_name,
            id: currentValue.id
        })

        return arr;
    }, [])
}

function getMessages(err: any) {
    return err.response.data.violations.map((violation: any) => {
        return violation.message
    });
}