import React from "react";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function ProjectName(props: { value: string | undefined, handleChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void }) {
    return (
        <TextField
            required
            name="projectName"
            value={props.value}
            onChange={(event) => props.handleChange(event)}
            label="Project Name"
            variant="outlined"
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <Tooltip placement="top" arrow title="Project name should be globally unique. The name should be representative of what the project is about. Ex. Urban Nature Project">
                        <IconButton edge="end"><HelpOutlineIcon/></IconButton>
                    </Tooltip>
                </InputAdornment>
            }}
        />
    )
}

export default ProjectName;