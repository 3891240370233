import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Autocomplete, TextField} from "@mui/material";
import {fetchProjects} from "@/services/ProjectService";
import ProjectType from "types/ProjectType";


function Project(props: { value: string | null, handleChange: (project: ProjectType | null) => void, helperText?: string}) {
    const [projects, setProjects] = useState<Array<ProjectType>>([])
    const [errors, setErrors] = useState<Array<string | undefined>>([]);

    const getAllProjects = async () => {
        try {
            const projects = await fetchProjects()
            setProjects(projects)
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    useEffect(() => {
        getAllProjects()
    }, [])


    const handleProjectChange = (e: React.SyntheticEvent, value: string|null) => {
        const project = projects.find(p => p.projectName === value)
        props.handleChange(project || null)
    }

    const projectNames = projects.map((project) => project.projectName)

    return (
        <>
            {errors.length > 0 && <Alert severity="error">
                <AlertTitle>Lookup project error(s):</AlertTitle>
                <ul>
                    {errors.map((error, index) => {
                        return (<li key={index}>{error}</li>)
                    })}
                </ul>
            </Alert>}
            <Autocomplete
                id={"project"}
                value={props.value}
                onChange={handleProjectChange}
                disablePortal
                options={projectNames.sort((a:any, b:any) => -b.localeCompare(a))}
                renderInput={(params) => <TextField {...params} required label="Project" helperText={props.helperText}/>}
                fullWidth
            />
        </>
    )
}

export default Project;