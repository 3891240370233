import { Box, Button, Chip, Drawer, Grid, IconButton, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { getAllSamplingProtocols } from "../../../services/apiCalls";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { ISamplingProtocol } from '../../../customTypes/SamplingProtocolsApiResponseType';
import DocumentType from '../../../customTypes/DocumentType';
import FaceIcon from '@mui/icons-material/Face';
import CopyToClipboard from 'react-copy-to-clipboard';

const renderHeader = ({ field }: GridColumnHeaderParams) => {
    return <Typography variant="overline" display="block">{field}</Typography>;
};



function ViewSamplingProtocols() {
    const [samplingProtocols, setSamplingProtocols] = useState<Array<ISamplingProtocol>>([])
    const [errors, setErrors] = useState<Array<string | undefined>>([]);
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
    const [toggleDrawer, setToggleDrawer] = useState<boolean>(false);
    const [selectedSamplingProtocol, setSamplingProtocol] = useState<ISamplingProtocol>();

    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'id',
            flex: 0.5,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { id } = params.row;
                return <Box onClick={(e: any) => e.stopPropagation()}>
                    <CopyToClipboard text={id} onCopy={() => setOpenSnackBar(true)}>
                        <Tooltip title={id}>
                            <IconButton color="primary" component="label" size="small">
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                </Box>
            }
        },
        {
            field: 'Sampling',
            flex: 4,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { sampling_protocol_name, sampling_protocol_description } = params.row;
                return <div>
                    <Typography variant="h6">{sampling_protocol_name}</Typography>
                    <Typography variant="subtitle2">{sampling_protocol_description.length > 75 ? `${sampling_protocol_description.substring(0, 75)}...` : sampling_protocol_description}</Typography>
                </div>
            },
        },
        {
            field: 'Owner',
            flex: 2,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { name, email } = params.row.owner;
                return <Chip
                    icon={<FaceIcon />}
                    label={name}
                    variant="outlined"
                    sx={{ mr: '5px', mb: '5px' }}
                    component="a"
                    href={`mailto:${email}`}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    onClick={(e: any) => e.stopPropagation()}
                />
            }
        },
        { field: 'Collection Method', flex: 2, renderHeader, renderCell: (params: GridRenderCellParams) => params.row.collection_method },
        { field: 'Sample Type', flex: 2, renderHeader, renderCell: (params: GridRenderCellParams) => params.row.sample_type },
        {
            field: 'Info Documents',
            flex: 3,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { sampling_protocol_information_documents } = params.row;
                const documents = sampling_protocol_information_documents.map(({ title, link }: DocumentType, index: number) => {
                    return <Chip
                        key={index}
                        icon={<FileDownloadIcon />}
                        size="small"
                        label={title}
                        variant="outlined"
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href={link}
                        onClick={(e: any) => e.stopPropagation()}
                        sx={{ mr: '5px', mb: '5px' }}
                    />
                });
                return documents;
            }
        }
    ];

    useEffect(() => {
        showAllSamplingProtocols()
    }, []);

    const handleRowClick = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setSamplingProtocol(params.row);
        setToggleDrawer(true);
    }

    const showAllSamplingProtocols = async () => {
        try {
            const samplingProtocols = await getAllSamplingProtocols()
            setSamplingProtocols(samplingProtocols);
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    return (
        <Grid container direction="column" style={{ paddingLeft: 20 }}>
            <Grid item id="heading">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h3" gutterBottom>Sampling Protocols</Typography>
                    <Button variant="contained" color="warning" startIcon={<AddCircleIcon />} onClick={() => navigate('/protocols/sampling/new')}>New Sampling Protocol</Button>
                </Stack>
            </Grid>
            <Grid item id="data" height={600}>
                <DataGrid
                    rows={samplingProtocols}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    onRowClick={handleRowClick}
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={openSnackBar}
                    onClose={() => setOpenSnackBar(false)}
                    message="ID is copied to clipboard"
                    autoHideDuration={5000}
                />
            </Grid>
            <React.Fragment key='project-drawer'>
                <Drawer
                    anchor="right"
                    open={toggleDrawer}
                    onClose={() => setToggleDrawer(false)}
                >
                    <Box sx={{ width: '25vw', p: '15px', bgcolor: '#EEE', height: '100%' }}>
                        <Typography variant='h5' gutterBottom>{selectedSamplingProtocol?.sampling_protocol_name}</Typography>
                        <Box sx={{ mb: 2, mt: 2 }}>
                            <Typography variant="overline" display="block">Sampling Protocol Id</Typography>
                            {selectedSamplingProtocol?.id && <CopyToClipboard text={selectedSamplingProtocol?.id} onCopy={(e: any) => {
                                setOpenSnackBar(true);
                            }}>
                                <span style={{ userSelect: 'all', cursor: 'pointer' }}>{selectedSamplingProtocol?.id}</span>
                            </CopyToClipboard>}
                        </Box>
                        <Typography variant="overline" display="block">Sampling Protocol Description</Typography>
                        <Typography variant="body2" gutterBottom>
                            {selectedSamplingProtocol?.sampling_protocol_description}
                        </Typography>
                        <Typography variant="overline" display="block">Project Owner</Typography>
                        <Box><Chip icon={<FaceIcon />} label={selectedSamplingProtocol?.owner.name} sx={{ mr: 1, mb: 1 }} color="secondary" /></Box>
                        <Typography variant="overline" display="block">Collection Method</Typography>
                        <Typography variant="body2" gutterBottom>{selectedSamplingProtocol?.collection_method}</Typography>

                        <Typography variant="overline" display="block">Sample Type</Typography>
                        <Typography variant="body2" gutterBottom>{selectedSamplingProtocol?.sample_type}</Typography>

                        <Box sx={{ mb: 2, mt: 2 }}>
                            <Typography variant="overline" display="block">Information Documents</Typography>
                            <Box>
                                {selectedSamplingProtocol?.sampling_protocol_information_documents.map(({ title, link }: DocumentType, index: number) => {
                                    return <Chip
                                        key={index}
                                        icon={<FileDownloadIcon />}
                                        size="small"
                                        label={title}
                                        sx={{ mr: '5px', mb: '5px' }}
                                        component="a"
                                        target="_blank"
                                        rel="noopener noreferrer nofollow"
                                        href={link}
                                        onClick={(e: any) => e.stopPropagation()}
                                    />
                                })}
                            </Box>
                        </Box>
                    </Box>
                </Drawer>

            </React.Fragment>
        </Grid>
    )
}

export default ViewSamplingProtocols;