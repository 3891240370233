import React, {useState} from "react";
import DocumentType from "../../../../customTypes/DocumentType";
import APIResponseType from "../../../../customTypes/APIResponseType";
import {Alert, AlertTitle, Button, Grid, Link, Stack} from "@mui/material";
import SamplingProtocolName from "../../../sampling_protocols/SamplingProtocolName";
import Description from "../../../common/Description";
import Documents from "../../../common/Documents";
import FormField from "../../../common/FormField";
import {EnvironmentalMonitoringSamplingProtocol} from "../../../../customTypes/EnvironmentalMonitoringAPIType";
import {postEnvironmentalMonitoringSamplingProtocol} from "../../../../services/apiCalls";
import { useNavigate } from "react-router-dom";

function CreateEnvironmentalMonitoringSamplingProtocolForm() {
    const [formData, setFormData] = useState<any | undefined>({});
    const [errors, setErrors] = useState<Array<string>>([]);
    const [success, setSuccess] = useState<boolean | undefined>()
    const navigate = useNavigate()

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        setFormData((values: any) => ({ ...values, [name]: value }))
    }

    const handleAddDocument = async (event: React.MouseEventHandler) => {
        const documents = formData.documents || []
        documents.push({ title: "", link: "" })

        setFormData((values: any) => ({ ...values, documents: documents }))
    }

    const removeDocument = async (index: number) => {
        const documents = formData.documents
        documents.splice(index, 1)

        setFormData((values: any) => ({ ...values, documents: documents }))
    }

    const handleDocumentChange = async (index: number, document: DocumentType) => {
        const documents = formData.documents || [{ title: "", link: "" }]
        documents[index] = document

        setFormData((values: any) => ({ ...values, documents: documents }))
    }

    const handleClick = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrors([])

        const response: APIResponseType<any> = await createEnvironmentalMonitoringSamplingProtocol(formData)

        document.getElementById("logo")?.scrollIntoView();

        setSuccess(response.isSuccessful)

        if (response.isSuccessful) {
            setErrors([])
            setFormData({})
        } else {
            setErrors(response.errorMessages || [])
        }
    }

    const getMessages = (err: any) => {
        return err.response.data.violations.map((violation: any) => {
            return violation.field.replace("create.sampling_protocol.", "") + " " + violation.message
        });
    }

    const createEnvironmentalMonitoringSamplingProtocol = async(formData: any): Promise<APIResponseType<void>> => {
        const payload: EnvironmentalMonitoringSamplingProtocol = {
            protocol_name: formData.samplingProtocolName,
            description: formData.description,
            owner: {
                name: formData.ownerName,
                email: formData.ownerEmail
            },
            documents: formData.documents,
        }
        try {
            await postEnvironmentalMonitoringSamplingProtocol(payload)
            return {isSuccessful: true}
        } catch (err: any) {
            const errorMessages = err.response.data.detail ? [err.response.data.detail] : getMessages(err)
            return {isSuccessful: false, errorMessages: errorMessages}
        }
    }

    return (
        <>
            <form onSubmit={handleClick}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div style={{ alignContent: "center", paddingTop: 10 }}>
                                    {success && <Alert>
                                        <AlertTitle>
                                            Sampling Protocol created successfully!
                                        </AlertTitle>
                                        <Link onClick={() => navigate("/environmental-monitoring/protocols/sampling")}>Show environmental monitoring sampling protocols</Link>
                                    </Alert>}
                                    {errors && errors.length > 0 && <Alert severity="error">
                                        <AlertTitle>Create sampling protocol error(s):</AlertTitle>
                                        <ul>
                                            {errors.map((error, index) => {
                                                return (<li key={index}>{error}</li>)
                                            })}
                                        </ul>
                                    </Alert>}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <h1>Create Environmental Monitoring Sampling Protocol</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <SamplingProtocolName value={formData.samplingProtocolName || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Description value={formData.description || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1} direction="row">
                                    <FormField fieldName={"ownerName"} value={formData.ownerName || ""} label={"Owner Name"} handleChange={handleChange}/>
                                    <FormField fieldName={"ownerEmail"} value={formData.ownerEmail || ""} label={"Owner Email"} handleChange={handleChange}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <h3>Further Information Documents</h3>
                                <Documents documents={formData.documents || []}
                                           handleAddDocument={handleAddDocument}
                                           removeDocument={removeDocument}
                                           handleDocumentChange={handleDocumentChange}
                                           minDocuments={0}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="warning" type={"submit"}>Create</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default CreateEnvironmentalMonitoringSamplingProtocolForm;
