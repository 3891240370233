import React from "react";
import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function SurveyName(props: {value: string | undefined, handleChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void})  {
    return(
        <TextField
            required
            name="surveyName"
            value={props.value}
            onChange={(event) => props.handleChange(event)}
            label="Survey Name"
            variant="outlined"
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <Tooltip placement="top" arrow title="A name to identify the survey by. This should be descriptive yet succinct enough to give anyone an idea of what the survey is about.">
                        <IconButton edge="end"><HelpOutlineIcon/></IconButton>
                    </Tooltip>
                </InputAdornment>
            }}
        />
    )
}

export default SurveyName;