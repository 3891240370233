import React from "react";
import {IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function FundingStructure(props: {value: string | undefined, handleChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void})  {
    return(
        <TextField
            required
            name="fundingStructure"
            value={props.value}
            onChange={(event) => props.handleChange(event)}
            label="Funding Structure"
            multiline
            rows={4}
            fullWidth
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <Tooltip placement="top" arrow title="Funding structure is a free text field that is used to describe the way funds have been allotted to this project.">
                        <IconButton edge="end"><HelpOutlineIcon/></IconButton>
                    </Tooltip>
                </InputAdornment>
            }}
        />
    )
}

export default FundingStructure;