export type QuestionResponse = {
    question_label: string,
    question_response: string
}

export type CommunityScienceDocumentType = {
    title: string,
    filename: string,
    link: string
}

export type ModalImageType = {
    title: string,
    filename: string,
    s3_render_url: string,
    document: CommunityScienceDocumentType
    position_string: string
}

export type TaxonResponse = {
    taxon_name: string,
    taxon_version_key: string,
    observation_count: number,
    question_responses: QuestionResponse[],
    document_list: CommunityScienceDocumentType[]
}


export type ICSSample = {
    id: string,
    submission_id: string,
    survey_id: string,
    first_name: string,
    last_name: string,
    sample_date: string,
    sample_start_datetime: string,
    sample_end_datetime: string,
    submission_datetime: string,
    sample_location: CommunityScienceLocationType,
    sample_notes: string,
    sample_observers_type: string,
    name_of_site: string,
    sample_weather: string,
    document_list: CommunityScienceDocumentType[],
    question_responses: QuestionResponse[],
    taxon_responses: TaxonResponse[],
    sample_observers_number: number,
    verification_completion_status: string
}

export type CommunityScienceLocationType = {
    latitude: number,
    longitude: number
}

export type CommunityScienceTaxonHierarchyType = {
    species: string,
    genus: string,
    family: string,
    order: string,
    class: string,
    superclass: string,
    phylum: string,
    kingdom: string
}

export type CommunityScienceTVKLookupResponseType = {
    species: string,
    genus: string,
    family: string,
    order: string,
    class: string,
    superclass: string,
    phylum: string,
    kingdom: string,
    match_status: string,
    search_query: string
}

export type CommunityScienceVerificationRecordResponseType = {
    id: string,
    created_datetime: string,
    active: boolean,
    verified_by: string,
    observation_count: number,
    taxon_version_key: string,
    media_ids: string[],
    level_1_status: string,
    level_2_status: string,
    taxon_hierarchy: CommunityScienceTaxonHierarchyType
}

export type CommunityScienceProcessingOutputResponseType = {
    id: string,
    taxon_name: string,
    taxon_version_key: string,
    sample_date: string,
    sample_start_datetime: string,
    sample_end_datetime: string,
    sample_id: string,
    survey_id: string,
    sample_location: CommunityScienceLocationType,
    verification_records: CommunityScienceVerificationRecordResponseType[]
}

export type CommunityScienceProcessingOutputRequestType = {
    taxon_name: string,
    taxon_version_key: string,
    sample_date: string,
    sample_start_datetime: string,
    sample_end_datetime: string,
    sample_id: string,
    survey_id: string,
    sample_location: CommunityScienceLocationType
}

export type CommunityScienceVerificationRecordRequestType = {
    active: boolean,
    verified_by: string,
    observation_count: number,
    taxon_version_key: string,
    media_ids: string[],
    level_1_status: string,
    level_2_status: string,
    taxon_hierarchy: CommunityScienceTaxonHierarchyType
}

export enum CommunityScienceVerificationCompletionStatusEnum {
    NOT_REQUIRED,
    NOT_IN_PROGRESS,
    IN_PROGRESS,
    COMPLETE
}