import ProcessingProtocolType from "../types/ProcessingProtocolType";
import {getAllProcessingProtocols} from "./apiCalls";
import IProcessingProtocol from "../types/FullProcessingProtocol";
import { CreateProcessingProtocolType } from "../types/CreateProcessingProtocolType";
import APIResponseType from "../types/APIResponseType";
import { RawAxiosResponseHeaders } from "axios";
import { getAxiosAuth } from "./axiosWrapper";

export async function fetchProcessingProtocols(): Promise<Array<ProcessingProtocolType>> {
    const processingProtocols = await getAllProcessingProtocols();
    return processingProtocols
        .map((value: IProcessingProtocol) => {
            const result: ProcessingProtocolType = {
                id: value.id,
                protocolName: value.protocol_name,
            };
            return result
        })
}

function getMessages(err: any) {
    return err.response.data.violations.map((violation: any) => {
        return violation.field.replace("create.processing_protocol.", "") + " " + violation.message
    });
}

async function postProcessingProtocol(payload: CreateProcessingProtocolType): Promise<RawAxiosResponseHeaders> {
    try {
        const response = await (await getAxiosAuth()).post(`/metadata-app/processing-protocols`, payload)
        return response.headers
    } catch (e) {
        throw e
    }
}

export async function createProcessingProtocol(formData: any): Promise<APIResponseType<void>> {
    const payload: CreateProcessingProtocolType = {
        protocol_name: formData.processingProtocolName,
        description: formData.description,
        process_category: formData.processCategory,
        target_gene: formData.targetGene,
        sequencing_type: formData.sequencingType,
        methods_statement: formData.methodsStatement,
        notes: formData.notes,
    }

    try {
        await postProcessingProtocol(payload)
        return {isSuccessful: true}
    } catch (err: any) {
        const errorMessages = err.response.data.detail ? [err.response.data.detail] : getMessages(err)
        return {isSuccessful: false, errorMessages: errorMessages}
    }
}