import {DataGrid, GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import {
    CommunityScienceTaxonHierarchyType,
    CommunityScienceVerificationRecordResponseType
} from "../../customTypes/CommunityScienceSamplesApiResponseType";
import {Button, Grid, Typography} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const recordHistoryColumns: GridColDef[] = [
    { field: 'level_1_status', headerName: 'Level 1 Status', flex: 3 },
    { field: 'level_2_status', headerName: 'Level 2 Status', flex: 3},
    { field: 'taxon_hierarchy', headerName: 'Species', flex: 3 ,
        valueFormatter: (params: GridValueFormatterParams<CommunityScienceTaxonHierarchyType>) => {
            return params.value.species? params.value.species : 'Not Found';
        }},
    { field: 'verified_by', headerName: 'Edited by', flex: 3 },
    { field: 'created_datetime', headerName: 'Date', flex: 4,
        valueGetter: params => {
            if(!params.value){
                return 'Not Found';
            }
            var date = new Date(params.value);
            return date.toDateString() + ' @ ' + date.toLocaleTimeString();
        }}
]

function VerificationRecordHistoryTable(props: {
    verificationRecordHistoryList: Array<CommunityScienceVerificationRecordResponseType>
    setVerificationTable: (arg0: boolean) => void
    loading: Boolean
}){
    return (
        <>
            <Grid container direction="column" >
                <Grid container padding="15px 0px">
                    <Grid xs={3}></Grid>
                    <Grid xs={6} textAlign="center">
                        <Typography variant="h5" gutterBottom alignContent="center">Verification Record History:</Typography>
                    </Grid>
                    <Grid xs={3}>
                            <Button onClick={()=> props.setVerificationTable(false)} variant='contained' color='warning'>
                                <ArrowBackIcon></ArrowBackIcon>
                                Back to records
                            </Button>
                    </Grid>
                </Grid>
                <Grid item id="verification_table" sx={{height:'40vh'}} alignItems='center'>
                    <DataGrid
                        rows={props.verificationRecordHistoryList}
                        columns={recordHistoryColumns}
                        pageSize={10}
                        hideFooterSelectedRowCount
                        loading={props.loading.valueOf()}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default VerificationRecordHistoryTable;
