import {getMediaDownloadUrl} from "./apiCalls";
import {Location} from "@remix-run/router";
import APIResponseType from "../types/APIResponseType";

function redirectTo(url: string) {
    console.log(`redirecting to ${url}`)
    window.location.assign(url)
}

export async function redirectToMedia(location: Location): Promise<APIResponseType<void>> {
    const relativeRedirect = location.pathname.substring("/r".length)

    try {
        const media = await getMediaDownloadUrl(relativeRedirect);
        redirectTo(media.url)
        return {isSuccessful: true}
    } catch (err: any) {
        return {isSuccessful: false, errorMessages: [err.message]}
    }
}