import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { redirectToMedia } from "../services/RedirectService";
import APIResponseType from "../types/APIResponseType";
import { Container } from "@mui/material";
import { styled } from "@mui/system";

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

function Redirect() {
    const location = useLocation();
    const [apiResponse, setApiResponse] = useState<APIResponseType<void> | null>(null)

    useEffect(() => {
        redirectToMedia(location)
            .then(setApiResponse)
    }, [location]);

    const renderApiResonse = () => {
        if (!apiResponse) {
            return <p>Please wait while we are fetching your download...</p>
        } else if (apiResponse.isSuccessful) {
            return <>
                <p>
                    Your download should have happened.
                </p>
                <p>
                    Check your browser's downloads.
                </p>
                <p>If you cannot find it, try refreshing this page</p>
            </>
        } else {
            return <>
                <p>Oops, something went wrong! (refreshing the page could solve this)</p>
                <ul>
                    {apiResponse.errorMessages && apiResponse.errorMessages.map(msg => <li>{msg}</li>)}
                </ul>
            </>
        }
    }

    return <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
            {renderApiResonse()}
        </StyledContent>
    </Container>
}

export default Redirect