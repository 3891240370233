import './App.css';
import {WithAuthenticatorProps,} from '@aws-amplify/ui-react';
import Router from './routes';

function App({user, signOut}: WithAuthenticatorProps) {
    return (
        <Router user={user} signOut={signOut}/>
    );
}

export default App;
