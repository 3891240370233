import { Box, Button, Chip, Drawer, Grid, IconButton, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import FaceIcon from '@mui/icons-material/Face';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useEffect, useState } from 'react';
import PersonType from '../../customTypes/PersonType';
import DocumentType from '../../customTypes/DocumentType';
import { ISurvey } from '../../customTypes/SurveysApiResponseType';
import { getAllSurveys } from "../../services/apiCalls";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';

function ViewSurveys() {
    const [surveys, setSurveys] = useState<Array<ISurvey>>([])
    const [errors, setErrors] = useState<Array<string | undefined>>([]);
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
    const [toggleDrawer, setToggleDrawer] = useState<boolean>(false);
    const [selectedSurvey, setSelectedSurvey] = useState<ISurvey>();

    const navigate = useNavigate();

    const renderHeader = ({ field }: GridColumnHeaderParams) => {
        return <Typography variant="overline" display="block">{field}</Typography>;
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            flex: 0.5,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { id } = params.row;
                return <Box onClick={(e: any) => e.stopPropagation()}>
                    <CopyToClipboard text={id} onCopy={() => setOpenSnackBar(true)}>
                        <Tooltip title={id}>
                            <IconButton color="primary" component="label" size="small">
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                </Box>
            }
        },
        {
            field: 'Survey',
            flex: 3,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { survey_name, survey_description, start_date, end_date } = params.row;
                return <div>
                    <Typography variant="h6">{survey_name}</Typography>
                    <Typography variant="subtitle2">{survey_description.length > 75 ? `${survey_description.substring(0, 75)}...` : survey_description}</Typography>
                    <Typography variant="overline" display="block" gutterBottom>{start_date} - {end_date}</Typography>
                </div>
            },
        },
        {
            field: 'Administrators',
            flex: 2,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { administrators } = params.row;
                return <div>
                    {administrators.map(({ name, email }: PersonType) => {
                        return <Chip
                            key={name}
                            icon={<FaceIcon />}
                            label={name}
                            variant="outlined"
                            sx={{ mr: '5px', mb: '5px' }}
                            component="a"
                            href={`mailto:${email}`}
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            onClick={(e: any) => e.stopPropagation()}
                        />
                    })}
                </div>
            }
        },
        {
            field: 'Instruction Documents',
            flex: 4,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { instruction_documents } = params.row;
                const handleDocument = (link: string) => (e: any) => {
                    e.stopPropagation();
                    document.location.href = link;
                }
                const documents = instruction_documents.map(({ title, link }: DocumentType, index: number) => {
                    const WithToolTip = ({ children }: any) => <Tooltip title={title} placement="top" arrow>{children}</Tooltip>
                    const DocumentChip = <Chip
                        key={index}
                        icon={<FileDownloadIcon />}
                        size="small"
                        label={title.length > 30 ? `${title.substring(0, 30)}...` : title}
                        variant="outlined"
                        onClick={handleDocument(link)}
                        sx={{ mr: '5px', mb: '5px' }}
                    />
                    return title.length > 30 ? <WithToolTip>{DocumentChip}</WithToolTip> : DocumentChip;
                });
                return documents;
            }
        },
        {
            field: 'project id',
            flex: 2,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { project_id } = params.row;
                return <CopyToClipboard text={project_id} onCopy={(e: any) => {
                    setOpenSnackBar(true);
                }}>
                    <span style={{ userSelect: 'all', cursor: 'pointer' }}>{project_id}</span>
                </CopyToClipboard>
            }
        },
        {
            field: 'Sampling Protocol Id',
            flex: 2,
            renderHeader,
            renderCell: (params: GridRenderCellParams) => {
                const { sampling_protocol_id } = params.row;
                return <CopyToClipboard text={sampling_protocol_id} onCopy={(e: any) => {
                    setOpenSnackBar(true);
                }}>
                    <span style={{ userSelect: 'all', cursor: 'pointer' }}>{sampling_protocol_id}</span>
                </CopyToClipboard>
            }
        },
    ];

    useEffect(() => {
        showAllSurveys()
    }, []);

    const handleRowClick = (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
        setSelectedSurvey(params.row);
        setToggleDrawer(true);
    }

    const showAllSurveys = async () => {
        try {
            const surveys = await getAllSurveys()
            setSurveys(surveys);
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    return (
        <Grid container direction="column" style={{ paddingLeft: 20 }}>
            <Grid item id="heading">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h3" gutterBottom>Surveys</Typography>
                    <Button variant="contained" color="warning" startIcon={<AddCircleIcon />} onClick={() => navigate('/surveys/new')}>New Survey</Button>
                </Stack>
            </Grid>
            <Grid item id="data" height={600}>
                <DataGrid
                    rows={surveys}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    onRowClick={handleRowClick}
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={openSnackBar}
                    onClose={() => setOpenSnackBar(false)}
                    message="ID is copied to clipboard"
                    autoHideDuration={5000}
                />
            </Grid>
            <React.Fragment key='project-drawer'>
                <Drawer
                    anchor="right"
                    open={toggleDrawer}
                    onClose={() => setToggleDrawer(false)}
                >
                    <Box sx={{ width: '25vw', p: '15px', bgcolor: '#EEE', height: '100%' }}>
                        <Typography variant='h5' gutterBottom>{selectedSurvey?.survey_name}</Typography>
                        <Box sx={{ mb: 2, mt: 2 }}>
                            <Typography variant="overline" display="block">Survey Id</Typography>
                            {selectedSurvey?.id && <CopyToClipboard text={selectedSurvey?.id} onCopy={(e: any) => {
                                setOpenSnackBar(true);
                            }}>
                                <span style={{ userSelect: 'all', cursor: 'pointer' }}>{selectedSurvey?.id}</span>
                            </CopyToClipboard>}
                        </Box>
                        <Typography variant="overline" display="block">Survey Description</Typography>
                        <Typography variant="body2" gutterBottom>
                            {selectedSurvey?.survey_description}
                        </Typography>
                        <Stack direction="row" spacing={5}>
                            <Box>
                                <Typography variant="overline" display="block">Start Date</Typography>
                                <Typography variant="overline" display="block">{selectedSurvey?.start_date}</Typography>
                            </Box>
                            <Box>
                                <Typography variant="overline" display="block">End Date</Typography>
                                <Typography variant="overline" display="block">{selectedSurvey?.end_date}</Typography>
                            </Box>
                        </Stack>

                        <Box sx={{ mb: 2 }}>
                            <Typography variant="overline" display="block">Administrators</Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Name</strong></TableCell>
                                            <TableCell><strong>E-mail</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedSurvey?.administrators.map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <a href={`mailto:${row.email}`}>{row.email}</a>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box sx={{ mb: 2, mt: 2 }}>
                            <Typography variant="overline" display="block">Project Id</Typography>
                            {selectedSurvey?.project_id && <CopyToClipboard text={selectedSurvey?.project_id} onCopy={(e: any) => {
                                setOpenSnackBar(true);
                            }}>
                                <span style={{ userSelect: 'all', cursor: 'pointer' }}>{selectedSurvey?.project_id}</span>
                            </CopyToClipboard>}
                        </Box>

                        {selectedSurvey?.sampling_protocol_id !== undefined
                            ?
                            <Box sx={{ mb: 2, mt: 2 }}>
                                <Typography variant="overline" display="block">Sample Protocol Id</Typography>
                                {selectedSurvey?.sampling_protocol_id && <CopyToClipboard text={selectedSurvey?.sampling_protocol_id} onCopy={(e: any) => {
                                    setOpenSnackBar(true);
                                }}>
                                    <span style={{ userSelect: 'all', cursor: 'pointer' }}>{selectedSurvey?.sampling_protocol_id}</span>
                                </CopyToClipboard>}
                            </Box>
                            :
                            <></>
                        }


                        <Box sx={{ mb: 2, mt: 2 }}>
                            <Typography variant="overline" display="block">Documents</Typography>
                            <Box>
                                {selectedSurvey?.instruction_documents.map(({ title, link }: DocumentType, index: number) => {
                                    return <Chip
                                        key={index}
                                        icon={<FileDownloadIcon />}
                                        size="small"
                                        label={title}
                                        sx={{ mr: '5px', mb: '5px' }}
                                        component="a"
                                        target="_blank"
                                        rel="noopener noreferrer nofollow"
                                        href={link}
                                        onClick={(e: any) => e.stopPropagation()}
                                    />
                                })}
                            </Box>
                        </Box>
                    </Box>

                </Drawer>

            </React.Fragment>
        </Grid>
    )
}

export default ViewSurveys;