import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Autocomplete, TextField} from "@mui/material";
import {fetchProcessingOutputs} from "../services/ProcessingOutputService";
import ProcessingOutputType from "../customTypes/ProcessingOutputType";

function ProcessingOutputs(props: { value: string | null, handleChange: (processingOutput: ProcessingOutputType | null) => void}) {
    const [processingOutputs, setProcessingOutputs] = useState<Array<ProcessingOutputType>>([])
    const [errors, setErrors] = useState<Array<string | undefined>>([]);

    const getAllProcessingOutputs = async () => {
        try {
            const processingOutputs = await fetchProcessingOutputs()
            setProcessingOutputs(processingOutputs)
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    useEffect(() => {
        getAllProcessingOutputs()
    }, [])


    const handleProcessingOutputChange = (e: React.SyntheticEvent, value: string|null) => {
        const processingOutput = processingOutputs.find(p => p.fileName === value)
        props.handleChange(processingOutput || null)
    }

    const processingOutputNames = processingOutputs.map((processingOutput) => processingOutput.fileName)

    return (
        <>
            {errors.length > 0 && <Alert severity="error">
                <AlertTitle>Lookup processing outputs error(s):</AlertTitle>
                <ul>
                    {errors.map((error, index) => {
                        return (<li key={index}>{error}</li>)
                    })}
                </ul>
            </Alert>}
            <Autocomplete
                id={"processing-output"}
                value={props.value}
                onChange={handleProcessingOutputChange}
                disablePortal
                options={processingOutputNames}
                renderInput={(params) => <TextField {...params} required label="Processing Output" fullWidth/>}
            />
        </>
    )
}

export default ProcessingOutputs;