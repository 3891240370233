import SamplingProtocolType from "@/types/SamplingProtocolType";
import React, {useEffect, useState} from "react";
import {fetchSamplingProtocols} from "../../../services/SamplingProtocolService";
import {Alert, AlertTitle, Autocomplete, TextField} from "@mui/material";
import {UserConfigType} from "./user-configs/UserConfigType";

export default function SamplingProtocols(
    props: {
        value: string | null,
        handleChange: (samplingProtocol: SamplingProtocolType | null) => void,
        username: string | null,
        envUserConfig: UserConfigType[] | null
    }
){
    const [samplingProtocols, setSamplingProtocols] = useState<Array<SamplingProtocolType>>([]);
    const [samplingProtocolNames, setSamplingProtocolNames] = useState<Array<string>>([]);
    const [errors, setErrors] = useState<Array<string | undefined>>([]);

    async function getAllSamplingProtocols() {
        try {
            return await fetchSamplingProtocols();
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    const setExternalUserSamplingProtocolsAndNames = (username: string, samplingProtocolList: SamplingProtocolType[]) => {
        let userConfig = props.envUserConfig!.find(user => {
            return user.username === username
        })
        if (userConfig === undefined || samplingProtocolList.length === 0) {
            return
        }
        let userConfigSamplingProtocolIds: string[] = userConfig.sampling_protocol_ids;
        let userSamplingProtocols: SamplingProtocolType[] = samplingProtocolList.filter(
            samplingProtocol => userConfigSamplingProtocolIds.some((samplingProtocolId) => samplingProtocol.id === samplingProtocolId)
        );
        setSamplingProtocolsAndNames(userSamplingProtocols);
    }

    const handleSamplingProtocolsAvailableToUser = () => {
        getAllSamplingProtocols()
            .then(samplingProtocols => {
                props.username == null ?
                    setSamplingProtocolsAndNames(samplingProtocols!) : setExternalUserSamplingProtocolsAndNames(props.username!, samplingProtocols!);
            })
    }

    useEffect(() => {
        handleSamplingProtocolsAvailableToUser()
    }, [])

    const setSamplingProtocolsAndNames = (samplingProtocolList: SamplingProtocolType[]) => {
        let samplingProtocolNamesList: string[] = [];
        samplingProtocolList.forEach(value => {
            samplingProtocolNamesList.push(value.samplingProtocolName)
        });
        setSamplingProtocols(samplingProtocolList);
        setSamplingProtocolNames(samplingProtocolNamesList);
    }

    const handleSamplingProtocolChange = (e: React.SyntheticEvent, value: string | null) => {
        const samplingProtocol = samplingProtocols.find(p => p.samplingProtocolName === value)
        props.handleChange(samplingProtocol || null)
    }

    return (
        <>
            {errors.length > 0 && <Alert severity="error">
                <AlertTitle>Lookup sampling protocol error(s):</AlertTitle>
                <ul>
                    {errors.map((error, index) => {
                        return (<li key={index}>{error}</li>)
                    })}
                </ul>
            </Alert>}
            <Autocomplete
                id="sampling-protocol"
                value={props.value}
                onChange={handleSamplingProtocolChange}
                disablePortal
                options={samplingProtocolNames.sort((a: any, b: any) => -b.localeCompare(a))}
                renderInput={(params) => <TextField {...params} required label="Sampling Protocol"/>}
                fullWidth
            />
        </>
    )

}