import React from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TextField} from "@mui/material";
import {Dayjs} from "dayjs";

function Date(props: {value: Dayjs | null, handleDatePick: (value: Dayjs | null) => void, label: string}) {
    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={props.label}
                value={props.value}
                onChange={props.handleDatePick}
                renderInput={(params) => <TextField {...params} required fullWidth/>}
                inputFormat="DD-MM-YYYY"
            />
        </LocalizationProvider>
    )
}

export default Date;