import React, { useState } from "react";

import { Alert, AlertTitle, Button, Grid, Link, Stack } from '@mui/material';
import APIResponseType from "@/types/APIResponseType";
import { createSamplingProtocol } from "../../services/SamplingProtocolService";
import { useNavigate } from "react-router-dom";
import SamplingProtocolName from "./SamplingProtocolName";
import Description from "../common/Description";
import Person from "../common/Person";
import PersonType from "@/types/PersonType";
import CollectionMethod from "./CollectionMethod";
import SampleType from "./SampleType";
import Documents from "../common/Documents";
import DocumentType from "@/types/DocumentType";

function CreateSamplingProtocolForm() {
    const [formData, setFormData] = useState<any | undefined>({});
    const [errors, setErrors] = useState<Array<string>>([]);
    const [success, setSuccess] = useState<boolean | undefined>()
    const navigate = useNavigate()

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        setFormData((values: any) => ({ ...values, [name]: value }))
    }

    const handleOwnerChange = async (person: PersonType) => {
        setFormData((values: any) => ({ ...values, owner: person }))
    }

    const handleAddInfoDocument = async (event: React.MouseEventHandler) => {
        const infoDocuments = formData.infoDocuments || [{ title: "", link: "" }]
        infoDocuments.push({ title: "", link: "" })

        setFormData((values: any) => ({ ...values, infoDocuments: infoDocuments }))
    }

    const removeInfoDocument = async (index: number) => {
        const infoDocuments = formData.infoDocuments
        infoDocuments.splice(index, 1)

        setFormData((values: any) => ({ ...values, infoDocuments: infoDocuments }))
    }

    const handleInfoDocumentChange = async (index: number, document: DocumentType) => {
        const infoDocuments = formData.infoDocuments || [{ title: "", link: "" }]
        infoDocuments[index] = document

        setFormData((values: any) => ({ ...values, infoDocuments: infoDocuments }))
    }

    const handleClick = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrors([])

        const response: APIResponseType<any> = await createSamplingProtocol(formData)

        document.getElementById("logo")?.scrollIntoView();

        setSuccess(response.isSuccessful)

        if (response.isSuccessful) {
            setErrors([])
            setFormData({})
        } else {
            setErrors(response.errorMessages || [])
        }
    }

    return (
        <>
            <form onSubmit={handleClick}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div style={{ alignContent: "center", paddingTop: 10 }}>
                                    {success && <Alert>
                                        <AlertTitle>
                                            Sampling Protocol created successfully!
                                        </AlertTitle>
                                        <Link onClick={() => navigate("/protocols/sampling")}>Show sampling protocols</Link>
                                    </Alert>}
                                    {errors && errors.length > 0 && <Alert severity="error">
                                        <AlertTitle>Create sampling protocol error(s):</AlertTitle>
                                        <ul>
                                            {errors.map((error, index) => {
                                                return (<li key={index}>{error}</li>)
                                            })}
                                        </ul>
                                    </Alert>}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <h1>Create Sampling Protocol</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <SamplingProtocolName value={formData.samplingProtocolName || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Description value={formData.description || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1} direction="row">
                                    <Person prefix="Owner" value={formData.owner || { name: "", email: "" }}
                                        onChange={(handleOwnerChange)}
                                        required={true} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <CollectionMethod value={formData.collectionMethod || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <SampleType value={formData.sampleType || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <h4>Further Information Documents</h4>
                            </Grid>
                            <Grid item xs={12}>
                                <Documents documents={formData.infoDocuments || [{ title: "", link: "" }]}
                                    prefix="Document"
                                    handleAddDocument={handleAddInfoDocument}
                                    removeDocument={removeInfoDocument}
                                    handleDocumentChange={handleInfoDocumentChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="warning" type={"submit"}>Create</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default CreateSamplingProtocolForm;