import { Chip } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function DownloadChip(
    props: {
        title: string,
        link: string
    }) {
    return (<Chip
        icon={<FileDownloadIcon />}
        size="small"
        label={props.title}
        variant="outlined"
        component="a"
        target="_blank"
        rel="noopener noreferrer nofollow"
        href={props.link}
        onClick={(e: any) => e.stopPropagation()}
        sx={{ mr: '5px', mb: '5px' }}
    />)
}

export default DownloadChip;