import { Button, Modal, Box } from "@mui/material";
import DownloadChip from "./DownloadChip";
import { CommunityScienceDocumentType, ModalImageType, TaxonResponse } from "@/types/CommunityScienceSamplesApiResponseType";
import { useCallback, useEffect, useState } from "react";
import { getAWSS3Link } from "../../services/apiCalls";
import { BoxStyle, DivStyle, NavStyle } from "./VerificationStyles";
import VerifiedIcon from '@mui/icons-material/Verified';

const defaultModalImageBoxContent = (
    <Box sx={BoxStyle}>
        <div style={DivStyle('5vh')}>
            <h4 id="modal-modal-title">Modal image not found - please reload and try again.</h4>
        </div>
    </Box>
)

function ImagePreviewModal(
    props: {
        selectedTaxonResponse: TaxonResponse,
        open: boolean,
        setOpen: (arg0: boolean) => void,
        selectedIndex: number,
        setSelectedIndex: (arg0: number) => void,
        modalDocuments: CommunityScienceDocumentType[] | null,
        verifiedImages: Array<string>
    }) {

    const [modal_image, setModalImage] = useState<ModalImageType | null>(null);
    const [modalImageBoxContent, setModalImageBoxContent] = useState<JSX.Element>(defaultModalImageBoxContent);
    
    const handleClose = useCallback(() => {
        props.setOpen(false);
        setModalImageBoxContent(defaultModalImageBoxContent);
        props.setSelectedIndex(0);
    }, [props])

    const handleNext = useCallback(() => {
        if (props.selectedIndex >= props.modalDocuments!.length - 1) {
            return;
        }
        props.setSelectedIndex(props.selectedIndex + 1);
    },[props]);

    const handlePrevious = useCallback(() => {
        if (props.selectedIndex <= 0) {
            return;
        }
        props.setSelectedIndex(props.selectedIndex - 1);
    },[props]);

    const handleOpen = useCallback(() => {
        let document = props.modalDocuments![props.selectedIndex];
        getAWSS3Link(document.link.replace(/https:\/\/data-ecosystem-(dev|qa|prod).nhm.ac.uk\/#\/r|http:\/\/127.0.0.1:3000\/#\/r/g, ""))
            .then((media) => setModalImage({
                title: document.title,
                filename: document.filename,
                s3_render_url: media.url,
                document: document,
                position_string: (props.selectedIndex + 1).toString() + " of " + props.modalDocuments!.length.toString()
            }));
    }, [props.modalDocuments, props.selectedIndex]);


    useEffect(() => {
        if (props.open && props.modalDocuments && props.selectedIndex !== null && props.modalDocuments[props.selectedIndex]) {
            handleOpen();
        }
    }, [handleOpen, props.modalDocuments, props.open, props.selectedIndex]);

    useEffect(() => {
        const isImageVerified = (link: string) => {
            let mediaId = link.substring(link.indexOf('media/')+6)
            if(props.verifiedImages.some(e => {return e === mediaId})){
                return <VerifiedIcon color="primary" height={150}></VerifiedIcon>
            }
        }

        if(modal_image && props.selectedTaxonResponse){
            setModalImageBoxContent(
                <Box sx={BoxStyle}>
                    <div style={DivStyle('5vh')}>
                        <h4 id="modal-modal-title">Taxon Version Key: {props.selectedTaxonResponse.taxon_version_key}</h4>
                    </div>
                    <div style={DivStyle('5vh')}>
                        <h5 id="modal-modal-title">{modal_image?.title}</h5>{isImageVerified(modal_image?.document.link!)}
                    </div>
                    <div style={{...DivStyle('50%'), height: '50vh', objectFit: 'cover'}}>
                        <img
                            style={{maxWidth: '80%', width: 'auto', height: '100%'}}
                            alt={modal_image?.filename}
                            src={modal_image?.s3_render_url}
                        />
                    </div>
                    <div style={DivStyle('5vh')}>
                        <DownloadChip key={modal_image?.filename!} title={modal_image?.document.filename!} link={modal_image?.document.link!} />
                    </div>
                    <div style={{flex: 1, display: 'flex', justifyContent: 'center', height: '4vh'}}>
                        <div style={{textAlign: 'center'}}><h5>{modal_image?.position_string}</h5></div>
                    </div>
                    <div style={NavStyle}>
                        <div style={{...DivStyle('5vh'), flexDirection: 'row'}}>
                            <div style={{display: 'flex', marginLeft: 'auto', flex: 1}}><Button variant='contained' onClick={handlePrevious}>Previous</Button></div>
                            <div><Button variant="contained" onClick={handleClose}>Close</Button></div>
                            <div style={{display: 'flex', marginRight: 'auto', flex: 1, justifyContent: 'flex-end'}}><Button variant='contained' onClick={handleNext}>Next</Button></div>
                        </div>
                    </div>
                </Box>
            );
        }
    }, [handleClose, handleNext, handlePrevious, modal_image, props.selectedTaxonResponse, props.verifiedImages]);
    
    return (
        <Modal
            open={props.open}
            onClose={handleClose}
        >
            {modalImageBoxContent}
        </Modal>
    )
}

export default ImagePreviewModal;