import React, { useState } from "react";

import { Alert, AlertTitle, Button, Divider, Grid, Link, Typography } from '@mui/material';
import ProjectName from "./ProjectName";
import APIResponseType from "@/types/APIResponseType";
import { createProject } from "../../services/ProjectService";
import Description from "../common/Description";
import StartDate from "../common/StartDate";
import EndDate from "../common/EndDate";
import { Dayjs } from "dayjs";
import People from "../common/People";
import Documents from "../common/Documents";
import PersonType from "@/types/PersonType";
import DocumentType from "@/types/DocumentType";
import DOI from "./DOI";
import Person from "../common/Person";
import { Stack } from "@mui/system";
import FundingOrganisation from "./FundingOrganisation";
import PartnerOrganisations from "./PartnerOrganisations";
import Licenses from "./Licenses";
import FundingStructure from "./FundingStructure";
import { useNavigate } from "react-router-dom";

function CreateProjectForm() {
    const [formData, setFormData] = useState<any | undefined>({});
    const [errors, setErrors] = useState<Array<string>>([]);
    const [success, setSuccess] = useState<boolean | undefined>()
    const navigate = useNavigate()

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        setFormData((values: any) => ({ ...values, [name]: value }))
    }

    const handleClick = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrors([])

        const response: APIResponseType<any> = await createProject(formData)

        document.getElementById("logo")?.scrollIntoView();

        setSuccess(response.isSuccessful)

        if (response.isSuccessful) {
            setErrors([])
            setFormData({})
        } else {
            setErrors(response.errorMessages || [])
        }
    }

    const handleOwnerChange = async (person: PersonType) => {
        setFormData((values: any) => ({ ...values, projectOwner: person }))
    }

    const handleAddTeamMember = async (event: React.MouseEventHandler) => {
        const teamMembers = formData.teamMembers || [{ name: "", email: "" }]
        teamMembers.push({ name: "" })

        setFormData((values: any) => ({ ...values, teamMembers: teamMembers }))
    }

    const removeTeamMember = async (index: number) => {
        const teamMembers = formData.teamMembers
        teamMembers.splice(index, 1)

        setFormData((values: any) => ({ ...values, teamMembers: teamMembers }))
    }

    const handleTeamMemberChange = async (index: number, person: PersonType) => {
        const teamMembers = formData.teamMembers || [{ name: "", email: "" }]
        teamMembers[index] = person

        setFormData((values: any) => ({ ...values, teamMembers: teamMembers }))
    }

    const handleAddProjectDocument = async (event: React.MouseEventHandler) => {
        const projectDocuments = formData.projectDocuments || [{ title: "", link: "" }]
        projectDocuments.push({ title: "", link: "" })

        setFormData((values: any) => ({ ...values, projectDocuments: projectDocuments }))
    }

    const removeProjectDocument = async (index: number) => {
        const projectDocuments = formData.projectDocuments
        projectDocuments.splice(index, 1)

        setFormData((values: any) => ({ ...values, projectDocuments: projectDocuments }))
    }

    const handleProjectDocumentChange = async (index: number, document: DocumentType) => {
        const projectDocuments = formData.projectDocuments || [{ title: "", link: "" }]
        projectDocuments[index] = document

        setFormData((values: any) => ({ ...values, projectDocuments: projectDocuments }))
    }

    const handleStartDatePick = (value: Dayjs | null) => {
        const name = "startDate"

        setFormData((values: any) => ({ ...values, [name]: value?.format("YYYY-MM-DD") }))
    }

    const handleEndDatePick = (value: Dayjs | null) => {
        const name = "endDate"

        setFormData((values: any) => ({ ...values, [name]: value?.format("YYYY-MM-DD") }))
    }

    return (
        <>
            <form onSubmit={handleClick}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div style={{ alignContent: "center", paddingTop: 10 }}>
                                    {success && <Alert>
                                        <AlertTitle>
                                            Project created successfully!
                                        </AlertTitle>
                                        <Link onClick={() => navigate("/projects")}>Show projects</Link>
                                    </Alert>}
                                    {errors && errors.length > 0 && <Alert severity="error">
                                        <AlertTitle>Create project error(s):</AlertTitle>
                                        <ul>
                                            {errors.map((error, index) => {
                                                return (<li key={index}>{error}</li>)
                                            })}
                                        </ul>
                                    </Alert>}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <h1>Create Project</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <ProjectName value={formData.projectName || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Description
                                    value={formData.description || ""}
                                    handleChange={handleChange}
                                    instruction="This is a free text field to add some details about the project to elaborate on its vision and goals."
                                />
                            </Grid>
                            <Grid item xs>
                                <StartDate value={formData.startDate || null} handleDatePick={handleStartDatePick} />
                            </Grid>
                            <Grid item xs>
                                <EndDate value={formData.endDate || null} handleDatePick={handleEndDatePick} />
                            </Grid>
                            <Grid item xs={12}>
                                <DOI value={formData.doi || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Project Owner</Typography>
                                <Typography variant="body2" sx={{color: '#637381'}}>The owner of a project is the person responsible for managing and executing the project. </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1} direction="row">
                                    <Person value={formData.projectOwner || { name: "", email: "" }}
                                        onChange={(handleOwnerChange)}
                                        required={true} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Team Members</Typography>
                                <Typography variant="body2" sx={{color: '#637381'}}>This is a list of people who are part of the team running the project. Again, the emails here can be used to restrict access to the data under the project, if required.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <People members={formData.teamMembers || [{ name: "", email: "" }]}
                                    handleAddPerson={handleAddTeamMember}
                                    removePerson={removeTeamMember}
                                    handlePersonChange={handleTeamMemberChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Funding Details</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <PartnerOrganisations value={formData.partnerOrganisations || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <FundingOrganisation value={formData.fundingOrganisation || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <FundingStructure value={formData.fundingStructure || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Documents</Typography>
                                <Typography variant="body2" sx={{color: '#637381'}}>This is a list of documents that can help provide further information about the project.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Documents documents={formData.projectDocuments || [{ title: "", link: "" }]}
                                    handleAddDocument={handleAddProjectDocument}
                                    removeDocument={removeProjectDocument}
                                    handleDocumentChange={handleProjectDocumentChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Licenses value={formData.licenses || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="warning" type={"submit"}>Create</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default CreateProjectForm;