import React, { useState } from "react";

import { Alert, AlertTitle, Button, Divider, Grid, Link, Typography } from '@mui/material';
import { Dayjs } from "dayjs";
import Project from "../../commonComponents/Project";
import SamplingProtocol from "./SamplingProtocol";
import SurveyName from "./SurveyName";
import Description from "../common/Description";
import StartDate from "../common/StartDate";
import EndDate from "../common/EndDate";
import ProjectType from '../../customTypes/ProjectType'
import SamplingProtocolType from "../../customTypes/SamplingProtocolType";
import APIResponseType from "../../customTypes/APIResponseType";
import { createSurvey } from "../../services/SurveyService";
import Documents from "../common/Documents";
import DocumentType from "../../customTypes/DocumentType";
import People from "../common/People";
import PersonType from "../../customTypes/PersonType";
import { useNavigate } from "react-router-dom";

function CreateSurveyForm() {
    const [formData, setFormData] = useState<any | undefined>({});
    const [errors, setErrors] = useState<Array<string>>([]);
    const [success, setSuccess] = useState<boolean | undefined>()
    const navigate = useNavigate()

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        setFormData((values: any) => ({ ...values, [name]: value }))
    }

    const handleAddInstructionDocument = async (event: React.MouseEventHandler) => {
        const instructionDocuments = formData.instructionDocuments || [{ title: "", link: "" }]
        instructionDocuments.push({ title: "", link: "" })

        setFormData((values: any) => ({ ...values, instructionDocuments: instructionDocuments }))
    }

    const removeInstructionDocument = async (index: number) => {
        const instructionDocuments = formData.instructionDocuments
        instructionDocuments.splice(index, 1)

        setFormData((values: any) => ({ ...values, instructionDocuments: instructionDocuments }))
    }

    const handleInstructionDocumentChange = async (index: number, document: DocumentType) => {
        const instructionDocuments = formData.instructionDocuments || [{ title: "", link: "" }]
        instructionDocuments[index] = document

        setFormData((values: any) => ({ ...values, instructionDocuments: instructionDocuments }))
    }

    const handleAddAdministrator = async (event: React.MouseEventHandler) => {
        const administrators = formData.administrators || [{ name: "", email: "" }]
        administrators.push({ name: "" })

        setFormData((values: any) => ({ ...values, administrators: administrators }))
    }

    const removeAdministrator = async (index: number) => {
        const administrators = formData.administrators
        administrators.splice(index, 1)

        setFormData((values: any) => ({ ...values, administrators: administrators }))
    }

    const handleAdministratorChange = async (index: number, person: PersonType) => {
        const administrators = formData.administrators || [{ name: "", email: "" }]
        administrators[index] = person

        setFormData((values: any) => ({ ...values, administrators: administrators }))
    }


    const handleProjectChange = async (project: ProjectType | null) => {
        if (project != null) {
            const { id, projectName } = project
            setFormData((values: any) => ({ ...values, projectId: id, projectName: projectName }))
        } else {
            setFormData((values: any) => ({ ...values, projectId: null, projectName: null }))
        }
    }

    const handleSamplingProtocolChange = (samplingProtocol: SamplingProtocolType | null) => {
        if (samplingProtocol != null) {
            const { id, samplingProtocolName } = samplingProtocol
            setFormData((values: any) => ({
                ...values,
                samplingProtocolId: id,
                samplingProtocolName: samplingProtocolName
            }))
        } else {
            setFormData((values: any) => ({ ...values, samplingProtocolId: null, samplingProtocolName: null }))
        }
    }

    const handleStartDatePick = (value: Dayjs | null) => {
        const name = "startDate"

        setFormData((values: any) => ({ ...values, [name]: value?.format("YYYY-MM-DD") }))
    }

    const handleEndDatePick = (value: Dayjs | null) => {
        const name = "endDate"

        setFormData((values: any) => ({ ...values, [name]: value?.format("YYYY-MM-DD") }))
    }

    const handleClick = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrors([])

        const response: APIResponseType<any> = await createSurvey(formData)

        document.getElementById("logo")?.scrollIntoView();

        setSuccess(response.isSuccessful)

        if (response.isSuccessful) {
            setErrors([])
            setFormData({})
        } else {
            setErrors(response.errorMessages || [])
        }
    }

    return (
        <>
            <form onSubmit={handleClick}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div style={{ alignContent: "center", paddingTop: 10 }}>
                                    {success && <Alert>
                                        <AlertTitle>
                                            Survey created successfully!
                                        </AlertTitle>
                                        <Link onClick={() => navigate("/surveys")}>Show surveys</Link>
                                    </Alert>}
                                    {errors && errors.length > 0 && <Alert severity="error">
                                        <AlertTitle>Create survey error(s):</AlertTitle>
                                        <ul>
                                            {errors.map((error, index) => {
                                                return (<li key={index}>{error}</li>)
                                            })}
                                        </ul>
                                    </Alert>}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <h1>Create Survey</h1>
                            </Grid>
                            <Grid item xs={12} id={"projects"}>
                                <Project value={formData.projectName || null} handleChange={handleProjectChange} helperText="A survey is always associated with one specific project."/>
                            </Grid>
                            <Grid item xs={12}>
                                <SurveyName value={formData.surveyName || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Description value={formData.description || ""} handleChange={handleChange} instruction="This free text exists to add more details about the survey." />
                            </Grid>
                            <Grid item xs={12}>
                                <SamplingProtocol value={formData.samplingProtocolName || null} handleChange={handleSamplingProtocolChange} helperText="A survey definition needs to also establish the specific protocol via which the survey will be conducted. "/>
                            </Grid>
                            <Grid item xs>
                                <StartDate value={formData.startDate || null} handleDatePick={handleStartDatePick} />
                            </Grid>
                            <Grid item xs>
                                <EndDate value={formData.endDate || null} handleDatePick={handleEndDatePick} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Instruction Documents</Typography>
                                <Typography variant="body2" sx={{color: '#637381'}}>Apart from a sampling protocol that a survey is always associated with, there could be additional guidelines that are usually furnished to the administrators and scientists participating in conducting the survey.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Documents documents={formData.instructionDocuments || [{ title: "", link: "" }]}
                                    handleAddDocument={handleAddInstructionDocument}
                                    removeDocument={removeInstructionDocument}
                                    handleDocumentChange={handleInstructionDocumentChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Administrators</Typography>
                                <Typography variant="body2" sx={{color: '#637381'}}>A survey administrator is the person who will help in conducting the survey. There could be more than one administrator.</Typography>
                                
                            </Grid>
                            <Grid item xs={12}>
                                <People members={formData.administrators || [{ name: "", email: "" }]}
                                    handleAddPerson={handleAddAdministrator}
                                    removePerson={removeAdministrator}
                                    handlePersonChange={handleAdministratorChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="warning" type={"submit"}>Create</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default CreateSurveyForm;