import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Drawer, Divider, List, ListItemText } from '@mui/material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import useResponsive from '@/hooks/useResponsive';
import NavSection from '@/components/layout/nav-section';
import navConfig from './config';
import logo from "nhm-logo.svg";
import { StyledNavItem, StyledNavItemIcon } from '@/components/layout/nav-section/styles';

const NAV_WIDTH = 280;

export default function Nav({ openNav, onCloseNav }: any) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Box>
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <RouterLink to="/">
          <Box component="img" sx={{ height: 60 }} alt="logo" src={logo} id="logo" />
        </RouterLink>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box>
        <List disablePadding sx={{ p: 1 }}>
          <StyledNavItem
            component="a"
            href="https://naturalhistorymuseum.sharepoint.com/:b:/r/sites/NHM-UNP-DataEcosystemworkstream/Shared%20Documents/General/TW%20Handover/User%20guides%20and%20videos/User%20Guide.pdf?csf=1&web=1&e=d5JxKU"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
              },
            }}
          >
            <StyledNavItemIcon><HelpCenterIcon /></StyledNavItemIcon>
            <ListItemText disableTypography primary="User Guide" />
          </StyledNavItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Box
      component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: NAV_WIDTH },
        }}
    >
        {isDesktop ? (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: NAV_WIDTH,
                bgcolor: 'background.default',
                borderRightStyle: 'dashed',
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>
      );
}
