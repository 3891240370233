import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Alert, AlertTitle, Button, Grid } from "@mui/material";
import Project from '@/components/project/Project'
import Survey from "@/components/survey/Survey";
import ProjectType from "@/types/ProjectType";
import SurveyType from "@/types/SurveyType";

import ProcessingOutput from "@/components/processing-output/ProcessingOutput";
import ProcessingOutputType from "@/types/ProcessingOutputType";
import SampleColumnRange from "@/components/sample-column-range/SampleColumnRange";
import UploadButton from "@/components/upload-button/UploadButton";
import SampleColumnRangeType from "@/types/SampleColumnRangeType";
import CreateObservationsFromForm from "@/types/CreateObservationsFromForm";
import { createObservations } from "@/services/ObservationService";
import APIResponseType from "@/types/APIResponseType";

function parseColumnRange(columnRange: string): SampleColumnRangeType | null {
    const columnRangeArray = columnRange.split("-");

    const start = Number(columnRangeArray[0]) - 1;
    const end = Number(columnRangeArray[1]) - 1;
    if (start >= 0 && end >= start) {
        return {
            start: start,
            end: end
        }
    } else {
        return null
    }
}

export default function UploadObservationsForm() {
    const uploadButtonRef = useRef<any>(null);
    const [project, setProject] = useState<ProjectType | null>(null);
    const [survey, setSurvey] = useState<SurveyType | null>(null);
    const [processingOutput, setProcessingOutput] = useState<ProcessingOutputType | null>(null);
    const [columnRange, setColumnRange] = useState<string>("");
    const [file, setFile] = useState<File | null>(null);
    const [responses, setResponses] = useState<Array<APIResponseType<any>>>([]);
    const [errors, setErrors] = useState<Array<string>>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    function clearFormDataAndErrors() {
        uploadButtonRef.current.value = ""
        setColumnRange("")
        setProject(null)
        setSurvey(null)
        setProcessingOutput(null)
        setFile(null)
        setErrors([])
        // setResponse should not be cleared
    }

    const handleFile = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        setFile(file)
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setIsUploading(true)
    }

    useEffect(() => {
        if (!isUploading) {
            return
        }

        const uploadObservations = async () => {
            if (!file ||
                !columnRange ||
                !survey ||
                !processingOutput) {
                setErrors(["Error must fill in all fields correctly"])
                setIsUploading(false)
                return;
            } else {
                const parsedColumnRange: SampleColumnRangeType | null = parseColumnRange(columnRange)
                if (!parsedColumnRange) {
                    setErrors(["Error formatting column range"])
                    setIsUploading(false)
                    return
                }

                const createObservationsFromForm: CreateObservationsFromForm = {
                    file: file,
                    surveyId: survey.id,
                    columnRange: parsedColumnRange,
                    processingOutput: processingOutput,
                };

                const response = await createObservations(createObservationsFromForm);
                setResponses(response)
                clearFormDataAndErrors();
            }
            setIsUploading(false)
        }

        uploadObservations()
    }, [isUploading])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <fieldset data-testid="fieldSet" disabled={isUploading} style={{ border: 'none' }}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item md={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {responses.map((res, index) => {
                                        return (
                                            <div key={index} style={{ alignContent: "center", paddingTop: 10 }}>
                                                {res.isSuccessful &&
                                                    <Alert severity={"success"}><AlertTitle>Observations created successfully for
                                                        physical
                                                        sample reference: <strong> {res.label} </strong></AlertTitle></Alert>}
                                                {!res.isSuccessful &&
                                                    <Alert severity={"error"}><AlertTitle>Error creating observations for physical
                                                        sample
                                                        reference: <strong> {res.label} </strong> </AlertTitle>
                                                        <ul>
                                                            {res.errorMessages && res.errorMessages.map((error: string, index: number) => {
                                                                return (<li key={index}>{error}</li>)
                                                            })}
                                                        </ul>
                                                    </Alert>}
                                            </div>
                                        );
                                    })}
                                    {errors.length > 0 && <Alert severity="error">
                                        <AlertTitle>Upload observations error(s):</AlertTitle>
                                        <ul>
                                            {errors.map((error, index) => {
                                                return (<li key={index}>{error}</li>)
                                            })}
                                        </ul>
                                    </Alert>}
                                </Grid>
                                <Grid item xs={12}>
                                    <h1>Upload observations</h1>
                                </Grid>
                                <Grid item id={"projects"} xs={12}>
                                    <Project value={project?.projectName || null} handleChange={setProject} />
                                </Grid>
                                <Grid item id={"surveys"} xs={12}>
                                    <Survey value={survey?.surveyName || null} handleChange={setSurvey} project={project} />
                                </Grid>
                                <Grid item id={"processing-outputs"} xs={12}>
                                    <ProcessingOutput value={processingOutput?.fileName || null}
                                        handleChange={setProcessingOutput} />
                                </Grid>
                                <Grid item id={"sampleColumnRange"} xs={12}>
                                    <SampleColumnRange columnRange={columnRange || ""} onChange={setColumnRange} />
                                </Grid>
                                <Grid item id={"upload"} xs={12}>
                                    <UploadButton inputRef={uploadButtonRef} handleFile={handleFile} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type={"submit"} disabled={isUploading}>Submit</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <a href="https://naturalhistorymuseum.sharepoint.com/:x:/r/sites/NHM-UNP-DataEcosystemworkstream/Shared%20Documents/General/TW%20Handover/eDNA%20upload%20templates/eDNA%20Observations%20Upload%20format.csv?d=wd642644e0bfd42f8b3d67e59fe5fbf05&csf=1&web=1&e=ff80gX" target="_blank" rel="noopener noreferrer">
                                        Click here to view observation file format</a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </fieldset>
            </form>
        </>
    )
}