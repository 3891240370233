import axios, {AxiosInstance} from "axios";
import {Auth} from "aws-amplify";

export async function getAxiosAuth(): Promise<AxiosInstance> {
    const cognitoUserSession = await Auth.currentSession();
    const jwtToken = cognitoUserSession.getIdToken().getJwtToken();
    return axios.create({headers: {Authorization: `Bearer ${jwtToken}`}});
}

export async function getAxios(): Promise<AxiosInstance> {
    return axios;
}