import {Button, Divider, Grid, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import AcousticsFileUploadForm from "./AcousticsFileUploadForm";
import UploadProgressAlert from "./UploadProgressAlert";
import prodSensorsConfig from "./user-configs/userconfig-prod.json";
import qaSensorsConfig from "./user-configs/userconfig-qa.json";
import devSensorsConfig from "./user-configs/userconfig-dev.json";
import devLocalSensorsConfig from "./user-configs/userconfig-devlocal.json";
import {Auth} from "aws-amplify";
import {UserConfigType} from "./user-configs/UserConfigType";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {useNavigate} from "react-router-dom";

function UploadAcousticsSamples() {
    const [openModal, setOpenModal] = useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [envUserConfig, setEnvUserConfig] = useState<UserConfigType[] | null>(null)
    const [username, setUsername] = useState<string | null>(null);

    const navigate = useNavigate();

    const addData = () => {
        setOpenModal(true)
    }

    useEffect(() => {
        setTimeout(() => {
            if(uploadComplete) {
                setUploadComplete(false)
                setUploadInProgress(false)
            }
        }, 15000)
    }, [uploadComplete]);

    const setUsernameIfUserIsExternal = () => {
        let user: string = ""
        Auth.currentAuthenticatedUser()
            .then(authUser => {
                user = authUser.username;
                return authUser.attributes.email;
            })
            .then(email => {
                return !email.toString().endsWith("@nhm.ac.uk")
            })
            .then(isUserExternal => {
                return isUserExternal ? setUsername(user) : null;
            })
    }

    useEffect(() => {
        setUsernameIfUserIsExternal();
    }, []);

    const getEnvSensorConfigFile = () => {
        let url: String = window.location.href;
        if (url.includes("data-ecosystem-prod")) {
            return setEnvUserConfig(prodSensorsConfig);
        }
        if (url.includes("data-ecosystem-qa")) {
            return setEnvUserConfig(qaSensorsConfig);
        }
        if (url.includes("data-ecosystem-dev")) {
            return setEnvUserConfig(devSensorsConfig);
        }
        return setEnvUserConfig(devLocalSensorsConfig);
    }

    useEffect(() => {
        if(username) {
            getEnvSensorConfigFile()
        }
        else{
            setEnvUserConfig(null);
        }
    }, [username]);


    return (
        <>
            <AcousticsFileUploadForm
                openModal={openModal}
                setOpenModal={setOpenModal}
                uploadInProgress={uploadInProgress}
                setUploadInProgress={setUploadInProgress}
                setUploadComplete={setUploadComplete}
                username={username}
                envUserConfig={envUserConfig}
            />
            <Grid container direction="column" style={{padding: 20}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h3" gutterBottom>Acoustics Monitoring</Typography>
                </Stack>
                <Grid item id="control">
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={4}>
                            <Button size="large" variant="contained" color="warning" onClick={addData}>Upload Acoustics Samples</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <UploadProgressAlert uploadInProgress={uploadInProgress} uploadComplete={uploadComplete} setUploadComplete={setUploadComplete}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item><Divider sx={{mb: 3, mt: 3}}/></Grid>
            </Grid>
        </>
    )
}

export default UploadAcousticsSamples;