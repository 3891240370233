import { Button, Card, CardActions, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ClassIcon from '@mui/icons-material/Class';
import { ReactElement } from "react";

function GridOfLinks(props: { links: { url: string, title: string, icon?: ReactElement, action: string, description?: string, }[] }) {
    const navigate = useNavigate();
    return (
        <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                {props.links.map(({ url, title, icon, description, action }) => (
                    <Grid item xs>
                        <Card sx={{ height: '100%' , width: '30%'}}>
                            <Stack sx={{ height: '100%' }} justifyContent="space-between">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">{title}</Typography>
                                    {description && <Typography variant="body2" color="text.secondary">{description}</Typography>}
                                </CardContent>
                                <CardActions>
                                    <Button startIcon={icon} variant="contained" color="warning" onClick={() => { navigate(url) }} size="medium" fullWidth>{`${action} ${title}`}</Button>
                                </CardActions>
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

function EnvironmentalMonitoring() {

    const definitionLinks = [
        {
            url: "/environmental-monitoring/protocols/sampling",
            title: "Sampling Protocols",
            icon: <ClassIcon sx={{ fontSize: 40 }} />,
            description: `Sampling protocol is the scientific method that is used to collect the original sample, for example, a thermometer deployed in a pond.`,
            action: "Go To"
        }
    ]

    return (<>
        <Grid container direction="column" style={{ padding: 20 }}>
            <Grid item id="definitions">
                <h2>Environmental Monitoring Dashboard</h2>
            </Grid>
            <GridOfLinks links={definitionLinks} />
        </Grid>
    </>)
}

export default EnvironmentalMonitoring;