import {Alert, Box, Modal} from "@mui/material";
import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useState
} from "react";
import AudioSensors from "./AudioSensors";
import {Sensor} from "../../../customTypes/AcousticsAPIType";
import FileUploadComponent from "./FileUploadComponent";
import SamplingProtocols from "./SamplingProtocols";
import SamplingProtocolType from "../../../customTypes/SamplingProtocolType";
import {UserConfigType} from "./user-configs/UserConfigType";

function AcousticsFileUploadForm(
    props: {
        openModal: boolean,
        setOpenModal: (arg0: boolean) => void,
        uploadInProgress: boolean,
        setUploadInProgress: (arg0: boolean) => void,
        setUploadComplete: (argo0: boolean) => void,
        username: string | null,
        envUserConfig: UserConfigType[] | null
    }
) {
    const [samplingProtocol, setSamplingProtocol] = useState<SamplingProtocolType | null>(null);
    const [sensor, setSensor] = useState<Sensor | null>(null);
    const [audioZipFile, setAudioZipFile] = useState<File | null>(null);
    const [configObject, setConfigObject] = useState<object | null>(null);
    const [disableFileUploadButton, setDisableFileUploadButton]  = useState<boolean>(true);

    const [configAlert, setConfigAlert] = useState<String | null>(null);
    const [audioAlert, setAudioAlert] = useState<String | null>(null);
    
    const audioFileTypes: String[] = ["application/zip", "application/x-zip-compressed"];
    const textFileTypes: String[] = ["text/plain"];
    const minimumFileSize: number = 5 * 1024 * 1024;

    const handleClose = useCallback(() => {
        props.setOpenModal(false);
    }, [props])

    const handleAudioZipFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let localFile: File = e.target.files[0]
            if(localFile.type === undefined || !audioFileTypes.includes(localFile.type)) {
                setAudioZipFile(null)
                setAudioAlert("Please upload a valid zip file in format .zip")
                return;
            }
            else if (localFile.size < minimumFileSize) {
                setAudioZipFile(null)
                setAudioAlert("Please upload a zip file greater than 5MB")
                return;
            }
            else{
                setAudioAlert(null)
                setAudioZipFile(localFile)
            }
        }
    };

    const handleConfigFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let localFile: File = e.target.files[0]
            if(localFile.type === undefined || !textFileTypes.includes(localFile.type)) {
                setConfigObject(null)
                setConfigAlert("Please upload a valid sensor config file in format .txt")
                return;
            }
            else{
                setConfigAlert(null)
                let reader = new FileReader();
                reader.onload = function(event) {
                    if(event?.target?.result) {
                        let config = {}
                        //iterate over rows in file and add each to config
                        event.target.result.toString().split('\n')
                            .map(row => row.split(':'))
                            .filter(row => row.length === 2)
                            .map(row => ({[row[0].trim()] : row[1].trim()}))
                            .forEach(row => config = {...config, ...row})
                        setConfigObject(config)
                    } else {
                        setConfigObject(null)
                        setConfigAlert("Error reading file. Please ensure the format is valid.")
                    }
                };
                reader.readAsText(localFile, 'UTF-8');
            }
        }
    };


    useEffect(() => {
        if(audioZipFile!==null && sensor && samplingProtocol) {
            setDisableFileUploadButton(false)
        }
        else{
            setDisableFileUploadButton(true)
        }
    }, [audioZipFile, configObject, sensor, samplingProtocol])

    const onSuccessfulUpload = () => {
        setAudioZipFile(null)
        setConfigObject(null)
        setSensor(null)
        handleClose()
    }

    if(props.openModal) {
        return (
            <Modal open={props.openModal} onClose={handleClose}>
                <Box className="acoustics-modal">
                    <div className="acoustics-modal-row">
                        <div className="acoustics-modal-row-item">
                            <h2>Upload Acoustics Samples</h2>
                            <p>To upload Audio Samples,
                                select an audio sensor from the drop-down,
                                upload the sensor config txt file (optional) and audio samples zip file.
                                Submit the data and wait for the upload to complete.</p>
                        </div>
                    </div>

                    <div className="acoustics-modal-row">
                        <h4>Select Sampling Protocol (Required)</h4>
                        <div className="acoustics-modal-row-item">
                            <SamplingProtocols
                                value={samplingProtocol?.samplingProtocolName || null}
                                handleChange={setSamplingProtocol}
                                username={props.username}
                                envUserConfig={props.envUserConfig}
                            />
                        </div>
                    </div>

                    <div className="acoustics-modal-row">
                        <h4>Select Audio Sensor (Required)</h4>
                        <div className="acoustics-modal-row-item">
                            <AudioSensors
                                value={sensor?.sensor_name || null}
                                handleChange={setSensor}
                                username={props.username}
                                envUserConfig={props.envUserConfig}
                            />
                        </div>
                    </div>

                    <div className="acoustics-modal-row">
                        <h4>Upload Sensor Config .txt File (Optional)</h4>
                        <div className="acoustics-modal-row-item">
                            <input id='configFileUpload' type='file' accept='text/plain'
                                   onChange={handleConfigFileUpload}/>
                        </div>
                        <div className="acoustics-modal-row-item">
                            {configAlert != null ?
                                <Alert variant='filled' severity='error'>{configAlert}</Alert> : <></>}
                        </div>
                    </div>

                    <div className="acoustics-modal-row">
                        <h4>Upload Audio Samples .zip File (Required. File must be greater than 5MB.)</h4>
                        <div>
                            <input id='audioFilesUpload' type='file'
                                   accept='application/zip, application/x-zip-compressed'
                                   onChange={handleAudioZipFileUpload}/>
                        </div>
                        <div className="acoustics-modal-row-item">
                            {audioAlert != null ?
                                <Alert variant='filled' severity='error'>{audioAlert}</Alert> : <></>
                            }
                        </div>
                    </div>

                    <FileUploadComponent
                        audioZipFile={audioZipFile}
                        configObject={configObject}
                        disableFileUploadButton={disableFileUploadButton}
                        onSuccessfulUpload={onSuccessfulUpload}
                        sensor={sensor}
                        samplingProtocol={samplingProtocol}
                        uploadInProgress={props.uploadInProgress}
                        setUploadInProgress={props.setUploadInProgress}
                        setUploadComplete={props.setUploadComplete}
                    ></FileUploadComponent>
                </Box>
            </Modal>
        )
    } else {
        return <></>
    }
}

export default AcousticsFileUploadForm;
