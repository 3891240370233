import React, { ChangeEvent, ReactElement } from 'react';
import { Button } from '@mui/material';
import Papa, { ParseResult } from 'papaparse';
import DisplayTabularData from './DisplayTabularData';

interface IProps {
}

interface IState {
  dataPreview: ReactElement;
}

class CSVReader extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {dataPreview: <></>};
        this.csvHandler = this.csvHandler.bind(this);
        this.onCsvParse = this.onCsvParse.bind(this);
    }

    onCsvParse = (results: ParseResult<string[]>) => {
        if (results.errors.length > 0) {
            console.error(`Error: ${results.errors.map(e => e.message).join()}`)
            return;
        }

        this.setState(_ => ({
            dataPreview: <DisplayTabularData data={results.data}/>
        }))
    }

    csvHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        Papa.parse(file, {
            complete: this.onCsvParse
        })
    }

    render() {
        return (
            <>
                <Button
                    component="label"
                    variant="outlined"
                    sx={{ marginRight: "1rem" }}>
                
                    <input type="file" accept=".csv" hidden onChange={this.csvHandler}/>
                    Upload
                </Button>

                {this.state.dataPreview}
            </>
        );
    }
}

export default CSVReader