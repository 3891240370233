import React from "react";
import {TextField} from "@mui/material";

function ProcessingProtocolName(props: {value: string | undefined, handleChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void})  {
    return(
        <TextField
            required
            name="processingProtocolName"
            value={props.value}
            onChange={(event) => props.handleChange(event)}
            label="Processing Protocol Name"
            variant="outlined"
            fullWidth
        />
    )
}

export default ProcessingProtocolName;