import {Alert} from "@mui/material";

export default function UploadProgressAlert (
    props: {
        uploadInProgress: boolean,
        uploadComplete: boolean,
        setUploadComplete: (arg0: boolean) => void
    }
) {
    if(props.uploadComplete) {
        return <Alert severity="success" variant="filled" onClose={()=> {props.setUploadComplete(false)}}>Upload Complete</Alert>
    }
    if(props.uploadInProgress) {
        return <Alert severity="success" variant="outlined">Upload In Progress</Alert>
    }
    else{
        return <></>
    }

}