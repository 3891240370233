import {TextField} from "@mui/material";
import React from "react";

function FormField(props: {
    fieldName: string
    value: string | undefined,
    label: string,
    required?: boolean,
    handleChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}) {
    return (
        <>
            <TextField
                required
                name={props.fieldName}
                value={props.value}
                onChange={(event) => props.handleChange(event)}
                label={props.label}
                variant="outlined"
                fullWidth
            />
        </>
    )
}

export default FormField;