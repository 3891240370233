import { Button, Grid, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {getAllObservations} from "../../services/apiCalls";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from 'react-router-dom';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'id', flex: 2 },
    { field: 'recordedDbTaxonName', headerName: 'Taxon Name', flex: 3 },
    // { field: 'numberOfOccurrences', headerName: 'Number Of Occurrences', flex: 1 },
    { field: 'percIdentity', headerName: 'Percentage Identity', flex: 1 },
    // { field: 'recordedTaxonAuthority', headerName: 'Taxon Authority', flex: 2 },
    // { field: 'recordedTaxonAttributeQualifier', headerName: 'Taxon Attribute Qualifier', flex: 2 },
    // { field: 'evidenceLink', headerName: 'Evidence Link', flex: 2 },
    { field: 'uksiTaxonName', headerName: 'UKSI Taxon Name', flex: 2 },
    // { field: 'uksiTaxonAuthority', headerName: 'UKSI Taxon Authority', flex: 2 },
    // { field: 'uksiTaxonAttributeQualifier', headerName: 'UKSI Taxon Attribute Qualifier', flex: 2 },
    { field: 'uksiTaxonID', headerName: 'UKSI Taxon ID', flex: 2 },
    { field: 'observerName', headerName: 'Observer Name', flex: 3 },
    // { field: 'datetime', headerName: 'Observation Date & Time', flex: 2 },
    // { field: 'sampleId', headerName: 'Sample Id', flex: 3 },
    // { field: 'processingOutputId', headerName: 'Processing Output Id', flex: 3 },
];

type ObservationType = {
    id: string;
    sampleId: string;
    processingOutputId: string;
    observerName: string;
    numberOfOccurrences: string;
    percIdentity: string;
    recordedDbTaxonName: string;
    recordedTaxonAuthority: string;
    recordedTaxonAttributeQualifier: string;
    uksiTaxonName: string;
    uksiTaxonAuthority: string;
    uksiTaxonAttributeQualifier: string;
    uksiTaxonID: string;
    evidenceLink: string;
    datetime: string;
}

function ViewObservations() {
    const [observations, setObservations] = useState<Array<ObservationType>>([])
    const [errors, setErrors] = useState<Array<string | undefined>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        showAllObservations()
    }, [])

    const showAllObservations = async () => {
        try {
            setLoading(true)
            const observations = await getAllObservations()
            setObservations(observations.map((observation) => {
                return {
                    id: observation.id,
                    sampleId: observation.sample_id,
                    processingOutputId: observation.processing_output_id,
                    observerName: observation.observer_name,
                    numberOfOccurrences: observation.number_of_occurrences,
                    percIdentity: observation.perc_identity,
                    recordedDbTaxonName: observation.recorded_db_taxon_name,
                    recordedTaxonAuthority: observation.recorded_taxon_authority,
                    recordedTaxonAttributeQualifier: observation.recorded_taxon_attribute_qualifier,
                    uksiTaxonName: observation.uksi_taxon_name,
                    uksiTaxonAuthority: observation.uksi_taxon_authority,
                    uksiTaxonAttributeQualifier: observation.uksi_taxon_attribute_qualifier,
                    uksiTaxonID: observation.uksi_taxon_id,
                    evidenceLink: observation.evidence_link,
                    datetime: observation.datetime
                }
            }))
            setLoading(false)
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    return (
        <Grid container direction="column" style={{paddingLeft: 20}}>
          <Grid item id="heading">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h3" gutterBottom>Observations</Typography>
                    <Button variant="contained" color="warning" startIcon={<CloudUploadIcon />} onClick={() => navigate('/eDNA/upload-observations')}>Upload Observation</Button>
                </Stack>
            </Grid>
          <Grid item id="data" height={600}>
            <DataGrid
                  rows={observations}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  pageSize={15}
                  loading={loading}
              />
          </Grid>
        </Grid>
    )
}

export default ViewObservations;