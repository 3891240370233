import React, { useState } from "react";

import { Alert, AlertTitle, Button, Grid, Link, Stack } from '@mui/material';
import APIResponseType from "@/types/APIResponseType";
import { createProcessingProtocol } from "../../services/ProcessingProtocolService";
import { useNavigate } from "react-router-dom";
import ProcessingProtocolName from "./ProcessingProtocolName";
import Description from "../common/Description";
import ProcessCategory from "./ProcessCategory";
import TargetGene from "./TargetGene";
import SequencingType from "./SequencingType";
import Document from "../common/Document";
import DocumentType from "@/types/DocumentType";

function CreateProcessingProtocolForm() {
    const [formData, setFormData] = useState<any | undefined>({});
    const [errors, setErrors] = useState<Array<string>>([]);
    const [success, setSuccess] = useState<boolean | undefined>()
    const navigate = useNavigate()

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        setFormData((values: any) => ({ ...values, [name]: value }))
    }

    const handleMethodsStatementChange = async (methodsStatement: DocumentType) => {
        setFormData((values: any) => ({ ...values, methodsStatement: methodsStatement }))
    }

    const handleClick = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrors([])

        const response: APIResponseType<any> = await createProcessingProtocol(formData)

        document.getElementById("logo")?.scrollIntoView();

        setSuccess(response.isSuccessful)

        if (response.isSuccessful) {
            setErrors([])
            setFormData({})
        } else {
            setErrors(response.errorMessages || [])
        }
    }

    return (
        <>
            <form onSubmit={handleClick}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div style={{ alignContent: "center", paddingTop: 10 }}>
                                    {success && <Alert>
                                        <AlertTitle>
                                            Processing Protocol created successfully!
                                        </AlertTitle>
                                        <Link onClick={() => navigate("/protocols/processing")}>Show processing protocols</Link>
                                    </Alert>}
                                    {errors && errors.length > 0 && <Alert severity="error">
                                        <AlertTitle>Create processing protocol error(s):</AlertTitle>
                                        <ul>
                                            {errors.map((error, index) => {
                                                return (<li key={index}>{error}</li>)
                                            })}
                                        </ul>
                                    </Alert>}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <h1>Create Processing Protocol</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <ProcessingProtocolName value={formData.processingProtocolName || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Description value={formData.description || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <ProcessCategory value={formData.processCategory || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TargetGene value={formData.targetGene || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <SequencingType value={formData.sequencingType || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={2}>
                                    <Document
                                        value={formData.methodsStatement || { title: "", link: "" }}
                                        prefix="Methods Statement"
                                        onChange={handleMethodsStatementChange}
                                        required
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Description required={false} label="Notes" name="notes" value={formData.notes || ""} handleChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="warning" type={"submit"}>Create</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default CreateProcessingProtocolForm;